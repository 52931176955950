import { Link } from "react-router-dom";
import Hero from "./Hero";

const Welcome = (
  {
    setLocation,
    location,
    setlatLong,
    history
  }
) => {
  return (
    <div className="welcome">
      <Hero history={history} setLocation={setLocation} location={location} setlatLong={setlatLong} />
      {/* <Link to="/categories" className="link-to-shops"></Link> */}
    </div>
  );
};

export default Welcome;
