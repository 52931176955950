import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Images from "../abstracts/Images";

const Footer = () => {
  // Redux state
  const user = useSelector((state) => state.user);

  const date = new Date().getFullYear();
  return (
    // Footer
    <footer>
      <div className="footer-card-container">
        <div className="footer-card">
          <h2>Have a question?</h2>
          <Link className="button" to="/contact">
            Contact support
          </Link>
          <p style={{ color: "#bbb", marginTop: "10px", textAlign: "center" }}>
            <i className="fas fa-phone"></i> +27640009442
          </p>
          <p style={{ color: "#bbb", marginTop: "10px", textAlign: "center" }}>
            <i className="fas fa-map-marker-alt"></i> 245 Zone F,
            <br />
            Lebowakgomo, Polokwane,
            <br />
            Limpopo, SA
          </p>
        </div>
        <div className="footer-card">
          <h2>Quick links</h2>
          <Link className="link" to="/about">
            About
          </Link>
          <Link className="link" to="/#pricing">
            Pricing
          </Link>
          <Link className="link" to="/about#contact">
            T's and C's
          </Link>
          <Link className="link" to="/help">
            FAQ
          </Link>
          <Link className="link" to="/returns">
            Return Policy
          </Link>
          <a className="link" href="https://www.facebook.com/Ordapple">
            <i className="fab fa-facebook-f"></i> Facebook
          </a>
          <a className="link" href="https://www.instagram.com/Ordapple">
            <i className="fab fa-instagram"></i>Instagram
          </a>
          <a
            className="link"
            href="https://www.youtube.com/channel/UCk3TFIj654lJ7qb6jCS_1jQ"
          >
            <i className="fab fa-youtube"></i>YouTube
          </a>

          {user._id && user.employee && (
            <>
              <h2 style={{ marginTop: "12px" }}>Staff only</h2>
              <Link className="link" to="/employee/mail">
                Employees
              </Link>

              <Link className="link" to="/employee/agents">
                Agents Commission
              </Link>

              <Link className="link" to="/employee/cashouts">
                Track Cashouts
              </Link>

              <Link className="link" to="/employee/orders">
                Manage Orders
              </Link>

              <Link className="link" to="/employee/gencode">
                Code Generator
              </Link>
            </>
          )}
        </div>
      </div>
      <div className="copyright raise">
        <div className="logos">
          <img src={Images.logos.payfast} alt="payfast logo" />
          <img src={Images.logos.mastercard} alt="mastercard logo" />
          <img src={Images.logos.visa} alt="visa logo" />
        </div>
        <br />
        &copy; <span className="footerYear">{date}</span> All rights reserved |
        Ordapple (Pty) Ltd
      </div>
    </footer>
  );
};

export default Footer;
