// USERS
const USERS = (state = [], { type, payload }) => {
  switch (type) {
    case "GET_USERS":
      return payload;
    case "EDIT_USER":
      const k = Object.keys(payload.data)[0];
      const v = Object.values(payload.data)[0];
      const ind = state.findIndex((el) => el._id === payload._id);
      state[ind][k] = v;

      return state;
    default:
      return state;
  }
};

// STATS
const STATS = (state = {}, { type, payload }) => {
  switch (type) {
    case "GET_STATS":
      return payload;
    default:
      return state;
  }
};

// ORDERS
const ORDERS = (state = [], { type, payload }) => {
  switch (type) {
    case "GET_ORDERS":
      return payload;
    case "DELETE_ORDER":
      const updatedOrders = state.filter((el, ind) => el._id !== payload);
      return updatedOrders;
    case "DELETE_ORDERS":
      return [];
    default:
      return state;
  }
};

const Admin = {
  USERS,
  STATS,
  ORDERS,
};
export default Admin;
