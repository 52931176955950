const GET_USERS = (data) => {
  return {
    type: "GET_USERS",
    payload: data,
  };
};
const EDIT_USER = (data) => {
  return {
    type: "EDIT_USER",
    payload: data,
  };
};
const GET_STATS = (data) => {
  return {
    type: "GET_STATS",
    payload: data,
  };
};
const GET_ORDERS = (data) => {
  return {
    type: "GET_ORDERS",
    payload: data,
  };
};
const DELETE_ORDER = (data) => {
  return {
    type: "DELETE_ORDER",
    payload: data,
  };
};
const DELETE_ORDERS = (data) => {
  return {
    type: "DELETE_ORDERS",
    payload: data,
  };
};

const Employee = {
  GET_USERS,
  EDIT_USER,
  GET_STATS,
  GET_ORDERS,
  DELETE_ORDER,
  DELETE_ORDERS,
};

export default Employee;
