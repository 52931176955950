import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FLASH, { RESTORE_FLASH } from "../../redux/actions/Flash";
import Employee from "../../redux/actions/Employee";
import JobNav from "./assets/JobNav";
import Loader from "../../abstracts/Loader";
import axios from "axios";
import Popup from "../../assets/Popup";
import Functions from "../../abstracts/Functions";
import OrdersCard from "../../assets/OrdersCard";
import { createRef } from "react";

const Orders = () => {
  // Redux state
  const flash = useSelector((state) => state.flash);
  const employee_orders = useSelector((state) => state.employee_orders);
  const [pagination, setPagination] = useState({});
  const [userFilter, setUserFilter] = useState("all");

  Functions.pageInfo({
    title: "Ordapple | Orders",
    desc: "View shops orders",
  });

  // Redux disparch
  const dispatch = useDispatch();

  // Use state
  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displaySearch, setDisplaySearch] = useState(false);
  const [searchQ, setSearchQ] = useState("");

  // Create refs
  const searchField = createRef();

  // Use effect
  useEffect(() => {
    dispatch(RESTORE_FLASH());

    axios({
      method: "GET",
      url: `${Functions.getAPI()}/employee/order?status=${userFilter}`,
      withCredentials: true,
    })
      .then(async (res) => {
        // Display popup
        setDisplay(true);

        // Waiting for the data before proceeding
        const data = await res.data;

        // Dispatching
        dispatch(Employee.GET_ORDERS(data.orders.data));

        // Setting state values
        setPagination({
          next: data.orders.next,
          previous: data.orders.previous,
          numOfPages: data.orders.numOfPages,
          error: data.orders.error,
        });
      })
      .catch((e) => {
        // Display popup
        setDisplay(true);

        const res = e.response;
        if (res) {
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
          console.log(res.data);
        } else {
          console.log(e);
        }
      });
  }, [dispatch, userFilter]);

  // Focus cursor on the text field
  useEffect(() => {
    searchField.current.focus();
  }, [displaySearch]);

  // Filtering the users you want to see (all, admins or buyers)
  const filter = (e) => {
    const { value } = e.target;
    setUserFilter(value);
  };

  // Searching for users by name
  const typeSearch = (e) => {
    const { value } = e.target;
    setSearchQ(value);
  };

  // Going to the next page or previous page
  const paginate = (e, limit, page) => {
    e.preventDefault();

    // Reset the states
    setPagination({});
    dispatch(Employee.GET_ORDERS([]));

    axios({
      url: `${Functions.getAPI()}/employee/order?filter=${userFilter}&pagelimit=${limit}&page=${page}`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        // If an error occurs while doing pagination
        // When reaching the end of the pages available for access
        // Take the user back to the 1st page
        if (pagination.error && "page" in data.orders.previous) {
          paginate(e, data.orders.previous.limit, 1);
        }

        // Dispatching
        dispatch(Employee.GET_ORDERS(data.orders.data));

        // Setting state values
        setPagination({
          next: data.orders.next,
          previous: data.orders.previous,
          numOfPages: data.orders.numOfPages,
          error: data.orders.error,
        });
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          console.log(res.data);
        } else {
          console.log(e);
        }
      });
  };

  // Toggle the display of the search feature
  const toggleSearch = (e) => {
    e.preventDefault();
    setDisplaySearch(!displaySearch);

    if (!displaySearch) {
      setSearchQ("");
    }
  };

  // Search for users
  const search = (e) => {
    e.preventDefault();
    setDisplaySearch(!displaySearch);
    // Display popup
    setDisplay(false);
    // Showing the loading message
    setLoading(true);

    axios({
      method: "GET",
      url: `${Functions.getAPI()}/employee/order?on=${searchQ}`,
      withCredentials: true,
    })
      .then(async (res) => {
        // Display popup
        setDisplay(true);
        // Showing the loading message
        setLoading(false);

        // Waiting for the data before proceeding
        const data = await res.data;
        // Dispatching
        dispatch(Employee.GET_ORDERS(data.orders.data));

        // Setting state values
        setPagination({
          next: data.orders.next,
          previous: data.orders.previous,
          numOfPages: data.orders.numOfPages,
          error: data.orders.error,
        });
      })
      .catch((e) => {
        // Display popup
        setDisplay(true);
        // Showing the loading message
        setLoading(false);

        const res = e.response;
        if (res) {
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
          console.log(res.data);
        } else {
          console.log(e);
        }
      });
  };

  // render the pagination buttons
  const renderPgBtns = () => {
    if (Object.keys(pagination).length > 0) {
      // Pagination users data
      if ("page" in pagination.previous || "page" in pagination.next) {
        return (
          <>
            {/*  Previous page */}
            <div className="pageForms">
              {"page" in pagination.previous && (
                <form className="pageForm">
                  <button
                    type="submit"
                    className="back"
                    onClick={(e) => {
                      paginate(
                        e,
                        pagination.previous.limit,
                        pagination.previous.page
                      );
                    }}
                  >
                    <i className="fas fa-chevron-left back"></i> Back
                  </button>
                </form>
              )}

              {/* Next page  */}
              {"page" in pagination.next && (
                <form className="pageForm">
                  <button
                    type="submit"
                    className="next"
                    onClick={(e) => {
                      paginate(e, pagination.next.limit, pagination.next.page);
                    }}
                  >
                    Next <i className="fas fa-chevron-right next"></i>
                  </button>
                </form>
              )}
            </div>
          </>
        );
      }
    }
  };

  // Render function
  const renderOrders = () => {
    if (employee_orders.length >= 1) {
      return (
        <>
          {/* Users data */}
          {employee_orders.map((order, ind) => (
            <OrdersCard
              key={order.o._id}
              buyer={false}
              display={display}
              setDisplay={setDisplay}
              dispatch={dispatch}
              orderNumber={order.o.orderNumber}
              dateOrdered={order.o.dateOrdered}
              itemsInOrder={order.o.itemsInOrder}
              customerName={order.o.customerName}
              customerEmail={order.o.customerEmail}
              customerPhone={order.o.customerPhone}
              customerAddress={order.o.customerAddress}
              pickUpTime={order.o.pickUpTime}
              elegibleForDelivery={order.o.elegibleForDelivery}
              totalPrice={order.o.totalPrice}
              status={order.o.status}
              trackingInfo={order.trackingInfo}
              agentCode={order.o.agentCode}
              _id={order.o._id}
              shopId={order.shop.id}
              shopName={order.shop.name}
            />
          ))}
          {/* Pagination */}
          {renderPgBtns()}
        </>
      );
    } else {
      const loader = () => {
        if (searchQ) {
          if (loading) {
            return <Loader />;
          } else {
            return (
              <h2
                className="non"
                style={{ fontSize: "20px", marginTop: "10px", color: "#bbb" }}
              >
                No results found!
              </h2>
            );
          }
        } else {
          <Loader />;
        }
      };
      return <div className="account-container">{loader()}</div>;
    }
  };

  return (
    <div>
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}

      <JobNav page="" />

      <div className="orders">
        <div className="orders-card-container">
          {/* Filter users */}
          <div className="delete-all-form">
            {/* Search button */}
            <button type="submit" className="search" onClick={toggleSearch}>
              <i className="fas fa-search"></i>
            </button>

            {/* Decoy */}
            <div
              className="decoy-cont"
              style={{ display: displaySearch ? "flex" : "none" }}
            >
              {/* Search feature */}
              <div
                className="search-cont"
                style={{ display: displaySearch ? "flex" : "none" }}
              >
                <button onClick={toggleSearch}>X</button>
                <input
                  type="text"
                  className="search-box"
                  placeholder="Order number"
                  onChange={typeSearch}
                  value={searchQ}
                  ref={searchField}
                />
                <button onClick={search}>
                  <i className="fas fa-arrow-right"></i>
                </button>
              </div>
            </div>

            {/* Filter users */}
            <select
              name="filter"
              id="filter"
              value={userFilter}
              onChange={filter}
            >
              <option value="all">All</option>
              <option value="pending payment">Pending payment</option>
              <option value="pending delivery">Pending delivery</option>
              <option value="complete">Complete</option>
            </select>
          </div>
          {/* Users data */}
          {renderOrders()}
        </div>
      </div>
    </div>
  );
};

export default Orders;
