const Tags = [
  "#women",
  "#men",
  "#sandals",
  "#sneakers",
  "#heals",
  "#tops",
  "#blouses",
  "#pants",
  "#jackets & couts",
  "#jeans",
  "#shorts",
  "#dresses",
  "#t-shirts",
  "#shirts",
  "#bags",
  "#head wear",
  "#socks",
  "#underwears",
  "#belts",
  "#formal",
  "#track suits",
  "#sweats",
  "#shoe care",
  "#hoodies",
  "#watches",
  "#batteries",
  "#face care",
  "#umbrellas",
  "#hair care",
  "#beard care",
  "#lipsticks & lip glosses",
  "#cellphones",
  "#appliances",
  "#vegies",
  "#fruits",
  "#bakeries",
  "#fast food",
  "#fitness & sports",
  "#drinks & beavorages",
  "#accessories",
  "#deodorants",
  "#perfumes",
];

export default Tags;
