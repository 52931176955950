import axios from "axios";
import { useEffect, useState } from "react";
import Functions from "../../../abstracts/Functions";

const UserTable = ({ user, toggleDisplayUpdater }) => {
  // React states
  const [balance, setBalance] = useState(0);
  const [transactionFees, setTransactionFees] = useState([]);

  // Request the transaction fees
  const getTransactionFees = () => {
    // Orders paid for
    const paidOrders = user.orders.filter(
      (i) => i.status !== "pending payment"
    );
    const transNums = paidOrders.map((i) => {
      return `${i.orderNumber}`;
    });

    // Request
    axios({
      url: `${Functions.getAPI()}/transaction/fees`,
      method: "POST",
      withCredentials: true,
      data: { trans: transNums }, // transNums is order numbers
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        setTransactionFees(data.data);
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          console.log(res.data);
          if (res.data.err) {
            console.log(res.data.err);
          } else {
            console.log(res.data.warn);
          }
        } else {
          console.log(e);
        }
      });
  };

  // Requesting transaction fees from server
  useEffect(getTransactionFees, []);

  // Use effect for rendering balance
  useEffect(() => {
    // Getting the balance after deductions
    const avail = Functions.transactionData(user.orders, transactionFees).map(
      (i) => {
        return Number(i.available);
      }
    );
    let balance;
    if (avail.length >= 1) {
      balance = avail.reduce((sum, price) => (sum += price));
    }

    // Checking if the balance is not undefined which means there are orders
    // which have status as PENDING DELIVERY OR COMPLETE
    if (balance) {
      setBalance(balance);
    } else {
      setBalance(0);
    }
  }, [transactionFees]);

  return (
    <div className="table">
      <form className="main-form" action="/employee/update-user" method="POST">
        <div className="heading">
          <h2>
            {user.firstName} {user.lastName}{" "}
            {user.admin && user.paid && user.paid ? (
              <span className="paid">paid</span>
            ) : (
              <span className="unpaid">unpaid</span>
            )}
          </h2>

          {/* Email */}
          <div className="content">
            <div>
              <p>
                <strong>ID: </strong>
                {user._id}
              </p>
            </div>
          </div>

          {/* Email */}
          <div className="content">
            <div>
              <p>
                <strong>Email: </strong>
                {user.email}
              </p>
            </div>
          </div>

          {/* Phone */}
          <div className="content">
            <div>
              <p>
                <strong>Phone: </strong>
                {user.phone}
              </p>
            </div>
          </div>

          {/* Admin */}
          <div className="content">
            <div>
              <p>
                <strong>Admin: </strong> {`${user.admin ? "Yes" : "No"}`}
              </p>
              <i
                data-name="admin"
                className="fa fa-pencil edit"
                aria-hidden="true"
                onClick={(e) => {
                  toggleDisplayUpdater(e, user._id);
                }}
              ></i>
            </div>
          </div>

          {/* Admin things */}
          {user.admin && (
            <>
              <div className="content">
                <div>
                  <p>
                    <strong>Num of items: </strong>{" "}
                    {user.products ? user.products : 0}
                  </p>
                </div>
              </div>
              <div className="content">
                <div>
                  <p>
                    <strong>Admin orders: </strong> {user.orders.length}
                  </p>
                </div>
              </div>
            </>
          )}

          {/* Account active */}
          <div className="content">
            <div>
              <p>
                <strong>Account active: </strong>
                {`${user.accountActive ? "Yes" : "No"}`}
              </p>
              <i
                data-name="accountActive"
                className="fa fa-pencil edit"
                aria-hidden="true"
                onClick={(e) => {
                  toggleDisplayUpdater(e, user._id);
                }}
              ></i>
            </div>
          </div>

          {/* Buyer orders */}
          <div className="content">
            <div>
              <p>
                <strong>Buyer orders: </strong>
                {user.buyerOrders}
              </p>
            </div>
          </div>

          {/* Subscription */}
          <div className="content">
            {user.subscription ? (
              <div>
                <p>
                  <strong>Subscription: </strong>
                  {user.subscription}
                </p>
                <i
                  data-name="subscription"
                  className="fa fa-pencil edit"
                  aria-hidden="true"
                  onClick={(e) => {
                    toggleDisplayUpdater(e, user._id);
                  }}
                ></i>
              </div>
            ) : (
              <div>
                <p>
                  <strong>Subscription: </strong>
                  None
                </p>
                <i
                  data-name="subscription"
                  className="fa fa-pencil edit"
                  aria-hidden="true"
                  onClick={(e) => {
                    toggleDisplayUpdater(e, user._id);
                  }}
                ></i>
              </div>
            )}
          </div>

          {/* Admin Verified */}
          {user.admin && (
            <div className="content">
              <div>
                <p>
                  <strong>Verified: </strong>{" "}
                  {`${user.verified ? "Yes" : "No"}`}
                </p>
                <i
                  data-name="verified"
                  className="fa fa-pencil edit"
                  aria-hidden="true"
                  onClick={(e) => {
                    toggleDisplayUpdater(e, user._id);
                  }}
                ></i>
              </div>
            </div>
          )}

          {/* Paid or unpaid */}
          {user.admin && (
            <div className="content">
              <div>
                <p>
                  <strong>Paid: </strong> {`${user.paid ? "Yes" : "No"}`}
                </p>
                <i
                  data-name="paid"
                  className="fa fa-pencil edit"
                  aria-hidden="true"
                  onClick={(e) => {
                    toggleDisplayUpdater(e, user._id);
                  }}
                ></i>
              </div>
            </div>
          )}

          {/* Admin balance */}
          {user.admin && (
            <div className="content">
              <div>
                <p>
                  <strong>Balance: </strong>
                  {balance}
                </p>
              </div>
            </div>
          )}
        </div>
        <input type="hidden" name="id" value="UserID" />
      </form>

      {/* Delete and update buttons */}
      {/* <form
        className="delete-form"
        action="/employee/delete-user"
        method="POST"
      >
        <input type="hidden" name="id" value="userID" />
        <button className="delete" type="submit">
          Save
        </button>
      </form> */}
    </div>
  );
};

export default UserTable;
