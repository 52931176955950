import ManageOrders from "../driver/ManageOrders";
import DriverRegister from "../driver/Register";
import DriverProtectedRoute from "../protectedRoutes/Driver";
import ProtectedRoute from "../ProtectedRoute";

const Driver = [
  {
    routeType: DriverProtectedRoute,
    path: "/driver/manage-orders",
    exact: true,
    component: ManageOrders,
  },
  {
    routeType: ProtectedRoute,
    path: "/driver/register",
    exact: true,
    component: DriverRegister,
  },
];

export default Driver;
