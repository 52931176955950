import GoogleSearchPlaces from "./GoogleSearchPlaces";
import GoogleMapComponent from "./GoogleMapComponent";

const GoogleMapsForm = ({
  updateStage,
  getValue,
  setLocation,
  setlatLong,
  latLong,
  location,
}) => {
  // Address/location/place found
  const address = location;

  const mapStyles =
    window.innerWidth <= 600
      ? {
          width: " 100%",
          height: "400px",
        }
      : {
          width: " 100%",
          height: "240px",
        };

  return (
    <div className="register-login login goggle-maps">
      <div className="form">
        <div className="heading">
          <h1>Step 1 (Location)</h1>
        </div>

        <form action="#">
          <div className="map-cont" style={{ width: "100%" }}>
            <GoogleSearchPlaces
              getValue={getValue}
              setLocation={setLocation}
              setlatLong={setlatLong}
              location={location}
            />
            <div id="map" style={mapStyles}>
              <GoogleMapComponent setCenter={latLong} />
            </div>
          </div>
          {/* Displaying selected place on the google maps  */}
          <p id="textLocation">{address}</p>

          <button id="next" onClick={updateStage}>
            Next
          </button>
        </form>
      </div>
    </div>
  );
};

export default GoogleMapsForm;
