import { Link } from "react-router-dom";
import axios from "axios";
import FLASH, { RESTORE_FLASH } from "../../redux/actions/Flash";
import USER from "../../redux/actions/User";
import { useSelector, useDispatch } from "react-redux";
import AuthActions from "../../redux/actions/AuthActions";
import Popup from "../../assets/Popup";
import { useState } from "react";
import Functions from "../../abstracts/Functions";

const BuyerDelAcc = () => {
  const flash = useSelector((state) => state.flash);

  Functions.pageInfo({
    title: "Ordapple | Manage Your Buyer Account",
    desc: "Manage your user account",
  });

  // Dispatch
  const dispatch = useDispatch();

  // State
  const [display, setDisplay] = useState(false);

  const deleteAccount = (e) => {
    e.preventDefault();
    dispatch(RESTORE_FLASH());

    // Show popup message
    setDisplay(true);

    // Loading animation on submit button
    e.target.classList.add("btn-animate");
    e.target.innerText = "Processing...";

    axios({
      url: `${Functions.getAPI()}/buyer/delete-account`,
      method: "POST",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        // Removing the loading animation from the button
        e.target.classList.remove("btn-animate");
        e.target.innerText = "Submit";

        // Dispatching
        dispatch(FLASH({ err: data.success, success: "", warn: "" }));
        dispatch(AuthActions.logout());
        dispatch(USER.DELETE_ACCOUNT());
        localStorage.setItem("auth", false);
        sessionStorage.removeItem("shopsInRadius");
      })
      .catch((err) => {
        // Removing the loading animation from the button
        e.target.classList.remove("btn-animate");
        e.target.innerText = "Submit";

        const res = err.response;
        if (res) {
          console.log(res.data);
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
        } else {
          console.log(err);
        }
      });
  };
  return (
    <>
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}
      <div className="register-login login">
        <div className="form">
          <div className="heading">
            <h1 style={{ textAlign: "center" }}>
              Are you sure you want to delete your account?
            </h1>
          </div>

          <form
            className="form-slide"
            action="/delete-account/userID"
            method="POST"
          >
            <button
              type="submit"
              className="submit-button"
              onClick={deleteAccount}
            >
              Yes
            </button>
            <br />
            <Link className="confirm-no" to="/buyer/edit-account">
              No
            </Link>
          </form>
        </div>
      </div>
    </>
  );
};

export default BuyerDelAcc;
