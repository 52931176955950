import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { useState, useCallback } from "react";
import BuyerLocationActions from "../redux/actions/BuyerLocationActions";
import { useDispatch } from "react-redux";

const GoogleMapComponent = ({ setCenter }) => {
  const [zoomLevel, setZoomLevel] = useState(7);

  const dispatch = useDispatch();

  const containerStyle = {
    // width: '400px',
    height: `240px`,
  };

  const center =
    setCenter && setCenter.lat !== null
      ? setCenter
      : {
          lat: -23.90449,
          lng: 29.46885,
        };
  console.log("center", center);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDCz5UAbpNZgUBCGxwh8b_FZn52pQlwf-8",
  });

  // for map
  const [map, setMap] = useState(null);

  const onLoad = useCallback(
    function callback(map) {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);

      setMap(map);

      // Timeout to set the zoom level
      // so that the map can show up
      setTimeout(() => {
        setZoomLevel(10);
      }, 3000);
    },
    [setCenter]
  );

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoomLevel}
      onLoad={onLoad}
      onClick={(e) => {
        console.log("(lat lng) On MAP click:", "GoogleMap", {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
          Event: e,
        });
        // dispatch(
        //   BuyerLocationActions.SET_BUYER_LOCATION_LATLONG_ACTION({
        //     lat: e.latLng.lat(),
        //     lng: e.latLng.lng(),
        //   })
        // );
        // dispatch(BuyerLocationActions.SET_BUYER_LOCATION_ADDRESS_ACTION(""));
      }}
      onUnmount={onUnmount}
    >
      <Marker position={center} animation="BOUNCE" />
      {/* Child components, such as markers, info windows, etc. */}
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default GoogleMapComponent;
