import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Functions from "../../abstracts/Functions";
import FLASH, { RESTORE_FLASH } from "../../redux/actions/Flash";
import axios from "axios";
import JobNav from "./assets/JobNav";
import Popup from "../../assets/Popup";

const AgentStats = () => {
  Functions.pageInfo({
    title: "Ordapple | Agents Management System",
    desc: "Manage Agents. For Authorized staff only",
  });

  // Use selectors
  const flash = useSelector((state) => state.flash);

  // Use state
  const [searchQ, setSearchQ] = useState("");
  const [comm, setComm] = useState(0);
  const [display, setDisplay] = useState(false);

  // Dispatch
  const dispatch = useDispatch();

  // Use effect
  useEffect(() => {
    dispatch(RESTORE_FLASH());
  }, []);

  // Typing the agent code
  const onChange = (e) => {
    const { value } = e.target;
    setSearchQ(value);
  };

  // Search for agents
  const search = (e) => {
    e.preventDefault();

    axios({
      method: "GET",
      url: `${Functions.getAPI()}/employee/agent/commission?q=${searchQ}`,
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        // Get the agent's commission
        setComm(data.cms);
      })
      .catch((e) => {
        setDisplay(true);

        const res = e.response;
        if (res) {
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
        } else {
          console.log(e);
        }
      });
  };

  return (
    <>
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}

      <JobNav page="" />
      <div className="account" style={{ marginTop: "-20px" }}>
        <div className="search-cont">
          <div className="inputs">
            <input
              type="text"
              placeholder="Agent code"
              value={searchQ}
              onChange={onChange}
            />

            <button onClick={search}>Go</button>
          </div>
          <p style={{ color: "#bbb", fontSize: "14px", textAlign: "center" }}>
            Search an agent to get their commission!
          </p>

          <div className="info">
            {/* Agent code */}
            <p style={{ color: "#777", fontSize: "14px" }}>
              Agent code: <span>{searchQ}</span>
            </p>

            {/* Commission */}
            <h1>
              Commision: <span> R{parseInt(Number(comm)).toFixed(2)}</span>{" "}
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentStats;
