import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const DriverProtectedRoute = ({ component: Component, ...rest }) => {
  // Use effect
  const auth = useSelector((s) => s.auth);
  const user = useSelector((s) => s.user);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth) {
          return <Redirect to="/login" />;
        } else if (!user.driver) {
          return <Redirect to="/home" />;
        } else {
          return <Component {...rest} {...props} />;
        }
      }}
    />
  );
};

export default DriverProtectedRoute;
