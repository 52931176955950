// INFORMATION
const ADMIN_INFO = (state = {}, { type, payload }) => {
  switch (type) {
    case "ADMIN_INFO":
      return payload;
    case "ADMIN_EDIT_ACCOUNT":
      if (state.followers) {
        state.name = payload.name;
        state.adminName = payload.adminName;
        state.about = payload.about;
        state.location = payload.location;
        return state;
      } else {
        return payload;
      }
    case "UPDATE_PROFILE_PIC":
      state.image = payload;
      return state;
    default:
      return state;
  }
};

// PRODUCTS
const PRODUCTS = (state = [], { type, payload }) => {
  switch (type) {
    case "PRODUCTS":
      return payload;
    case "ADD_PRODUCT":
      return [...state, payload];
    case "EDIT_PRODUCT":
      // Getting values and Keys of the edited product
      const k = Object.keys(payload);
      const v = Object.values(payload);

      // Get the index of the edited product
      const ind = state.findIndex((el) => el._id === payload._id);

      // For every key, set the updated values to the
      // modified/edited product
      k.forEach((key, i) => {
        state[ind][key] = v[i];
      });

      return state;
    case "REMOVE_PRODUCT":
      const updatedProducts = state.filter((el, ind) => el._id !== payload);
      return updatedProducts;
    default:
      return state;
  }
};

// ORDERS
const ORDERS_REDUCER = (state = [], { type, payload }) => {
  switch (type) {
    case "ORDERS":
      return payload;
    case "ADD_ORDER":
      return [...state, payload];
    case "EDIT_ORDER_STATUS":
      // Get the index of the edited order
      const ind = state.findIndex((el) => el._id === payload._id);
      state[ind].status = payload.status;

      return state;
    case "REMOVE_ORDER":
      const updatedOrders = state.filter(
        (el, ind) => el.orderNumber !== payload
      );
      return updatedOrders;
    case "REMOVE_ALL_ORDERS":
      return [];
    default:
      return state;
  }
};

const Admin = {
  ADMIN_INFO,
  PRODUCTS,
  ORDERS_REDUCER,
};
export default Admin;
