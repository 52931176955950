// Logos
import logo512  from "../img/logos/ordapple (512x512).png";
import payfast  from "../img/logos/payfast-logo.png";
import mastercard  from "../img/logos/mastercard.png";
import visa  from "../img/logos/visa.png";

// Illustration
import avatar from "../img/avatar.png";
import illustrator from "../img/404 illustrator.png";
import package_box from "../img/package_box.png";
import check from "../img/check.png";
import cross from "../img/cross.png";

// Founders' images
import me from "../img/founders/my-pic.jpg";
import him from "../img/founders/his-pic.jpg";

// Banner images
import banner1 from "../img/banners/Ordapple home banner(fashion)_comp.jpg";
import banner2 from "../img/banners/Ordapple home banner(food)_comp.jpg";
import banner3 from "../img/banners/Ordapple home banner(gadgets)_comp.jpg";
import banner4 from "../img/banners/Ordapple home banner(books)_comp.jpg";
import heroBanner from "../img/banners/Ordapple hero banner.png";

const founders = {
  me,
  him,
};

const banners = {
  hero: heroBanner,
  slides: [
    banner1,
    banner2,
    banner3,
    banner4,
  ]
}

const images = {
  logos: {
    logo512: logo512,
    payfast,
    mastercard,
    visa,
  },
  avatar,
  banners,
  founders,
  illustrator,
  package_box,
  check,
  cross,
};

export default images;
