import { createRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FLASH, { RESTORE_FLASH } from "../../../redux/actions/Flash";
import axios from "axios";
import Messages from "../../../abstracts/Messages";
import BuyerActions from "../../../redux/actions/BuyerActions";
import AdminActions from "../../../redux/actions/AdminActions";
import Functions from "../../../abstracts/Functions";
import { SetStatus, NewInbox } from "../../../assets/Requests";
import { Link } from "react-router-dom";

const OrderForm = ({ updateStage, location, latLong, addressInfo, mapInputVal, history }) => {
  // Getting data from redux state
  const name = useSelector((state) => state.user.firstName);
  const user = useSelector((state) => state.user);
  const flash = useSelector((state) => state.flash);
  const address = {location: {place: location, latLng: latLong}, addressInfo};

  // Dispatch
  const dispatch = useDispatch();

  // use state
  const [phone, setPhone] = useState("");
  const [pickUpTime, setPickUpTime] = useState("");
  const [agentCode, setAgentCode] = useState("");
  const [msg, setMsg] = useState({});

  // REF
  const phoneRef = createRef();
  const agentCodeRef = createRef();

  // Use effect hook
  useEffect(() => {
    // Restoring the flash messages
    dispatch(RESTORE_FLASH());
  }, [dispatch]);

  // Phone regular expression
  const phoneRegx = /^[0-1][6-8]\d{8}$/g;

  const onChange = (e) => {
    const { name, value, style } = e.target;

    if (name === "phone") {
      setPhone(value);
      if (value.length !== 10) {
        style.border = "3px solid red";
        setMsg({ phone: "Must be 10 digits!", agentCode: "" });
      } else if (!phoneRegx.test(value)) {
        style.border = "3px solid red";
        setMsg({ phone: "Invalid phone number!", agentCode: "" });
      } else {
        setMsg({ phone: "", agentCode: "" });
        style.border = "3px solid rgb(0, 200, 0)";
      }
    } else if (name === "pickUpTime") {
      setPickUpTime(value);
    } else if (name === "agentCode") {
      setAgentCode(value);
      if (value.length < 24) {
        style.border = "3px solid red";
        setMsg({ phone: "", agentCode: "Invalid agent code!" });
      } else {
        setMsg({ phone: "", agentCode: "" });
        style.border = "3px solid rgb(0, 200, 0)";
      }
    }
  };

  // Order function
  const order = (e) => {
    e.preventDefault();

    // Restoring the flash messages
    dispatch(RESTORE_FLASH());

    if (location === "") {
      dispatch(FLASH({ warn: "No location specified!", err: "", success: "" }));
    }
    else if (addressInfo === "") { dispatch(FLASH({ warn: "No address specified!", err: "", success: "" })); }
    else {
      if (phoneRef.current.value === "") {
        phoneRef.current.style.border = "3px solid red";
        setMsg({ phone: "Required field!", agentCode: "" });
      } else if (phoneRef.current.value.length !== 10) {
        phoneRef.current.style.border = "3px solid red";
        setMsg({ phone: "Must be 10 digits!", agentCode: "" });
      } else if (!phoneRegx.test(phoneRef.current.value)) {
        phoneRef.current.style.border = "3px solid red";
        setMsg({ phone: "Invalid phone number!", agentCode: "" });
      }
      // For Agent code
      // else if (
      //   agentCodeRef.current.value &&
      //   agentCodeRef.current.value.length < 24
      // ) {
      //   agentCodeRef.current.style.border = "3px solid red";
      //   setMsg({ phone: "", agentCode: "Invalid agent code!" });
      // }
      else {
        // Loading animation on submit button
        e.target.classList.add("btn-animate");
        e.target.innerText = "Processing...";

        axios({
          url: `${Functions.getAPI()}/order`,
          method: "POST",
          withCredentials: true,
          data: { address, phone, pickUpTime, agentCode },
        })
          .then(async (res) => {
            // Waiting for the data before proceeding
            const data = await res.data;

            // Removing the loading animation from the button
            e.target.classList.remove("btn-animate");
            e.target.innerText = "Submit";

            dispatch(BuyerActions.BUYER_ADD_ORDER(data.data));
            dispatch(BuyerActions.EMPTY_CART());
            if (user.admin) {
              dispatch(AdminActions.ADMIN_ADD_ORDER(data.data));
            }

            // Sending an inbox to a shop
            data.data.shops.map(async (s) => {
              if (user._id !== s) {
                await NewInbox(
                  {
                    text: "Ordered from your shop",
                    owner: s,
                    sender: user._id,
                    username: `${user.firstName}`,
                    url: "/admin/orders",
                  },
                  async () => {
                    await SetStatus(s);
                  }
                );
              }
              return "done";
            });

            // Sending notification to the shops
            const shopIds = data.data.itemsInOrder.map((item) => {
              return item.shopId;
            });
            const uniqueIds = shopIds.filter(Functions.uniqueArr);
            uniqueIds.map((_id) => {
              Functions.sendNotif({
                _id: _id,
                body: `${name} placed an order!`,
                url: "https://www.ordapple.com/admin/orders",
                fetchUrl: `${Functions.getAPI()}/sendNotif`,
              });
              return _id;
            });

            history.push(`/order/${data.data.orderNumber}`);
          })
          .catch((err) => {
            // Removing the loading animation from the button
            e.target.classList.remove("btn-animate");
            e.target.innerText = "Submit";

            const res = err.response;
            if (res) {
              console.log(res.data);
              if (res.data.err) {
                dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
              } else {
                dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
              }
            } else {
              console.log(err);
            }
          });
      }
    }
  };
  return (
    <div style={{ display: "flex" }} className="register-login login ">
      <div className="form">
        <div className="heading">
          <h1>Last Step (Order info)</h1>
        </div>

        {/* Flash messages */}
        {flash.err !== "" && <Messages err={flash.err} />}
        {flash.warn !== "" && <Messages warn={flash.warn} />}

        {/* Form */}
        <form className="form-slide" method="POST">
          <input
            required
            type="hidden"
            className="formInput"
            name="address"
            id="address"
            placeholder="Your address"
            value={location !== "" ? location : mapInputVal}
          />
          <p style={{ color: "red" }} id="address-error"></p>

          {/* Phone */}
          <input
            required
            type="tel"
            className="formInput"
            name="phone"
            id="phone"
            placeholder="Cellphone"
            value={phone}
            onChange={onChange}
            ref={phoneRef}
          />
          <p style={{ color: "red" }} id={msg.phone && "err"}>
            {msg.phone}
          </p>

          {/* Agent code */}
          {/* <input
            type="text"
            className="formInput"
            name="agentCode"
            id="agentCode"
            placeholder="Agent code"
            value={agentCode}
            onChange={onChange}
            ref={agentCodeRef}
          />
          <p style={{ color: "red" }} id={msg.agentCode && "err"}>
            {msg.agentCode}
          </p> */}

          {/* Pick up time */}
          <div className="passwords">
            <select
              name="pickUpTime"
              value={pickUpTime}
              id="pickUpTime"
              onChange={onChange}
            >
              <option value="">(Select pick up time)</option>
              <option value="ASAP">Pick up time: (ASAP)</option>
              <option value="After 30m">Pick up time: (after 30 min)</option>
              <option value="After 1h">Pick up time: (After 1h)</option>
              <option value="After 2-6h">Pick up time: (After 2-6h)</option>
              <option value="Later today">Pick up time: (Later today)</option>
              <option value="Tomorrow">Pick up time: (Tomorrow)</option>
            </select>
          </div>
          <p style={{ color: "#bbb", fontStyle: "italic" }}>
            Only applicable to Fast Food orders! (optional)
          </p>
          <p style={{ color: "red" }} id="pickUpTime-error"></p>

          <br />
          <button type="submit" className="submit-button" onClick={order}>
            Order
          </button>
          <br />
          <Link
            style={{ width: "100%", display: "inline", textAlign: "center" }}
            id="back"
            to="/order-info"
            onClick={(e)=>{
              updateStage(e, true)
            }}
          >
            <i style={{ marginRight: "5px" }} className="fas fa-arrow-left"></i>{" "}
            Back
          </Link>
        </form>
      </div>
    </div>
  );
};

export default OrderForm;
