import { useState } from "react";
import GoogleMapsForm from "../../assets/GoogleMapsForm";
import OrderForm from "./assets/OrderForm";
import ErrorBoundary from "../ErrorBoundary";
import Functions from "../../abstracts/Functions";
import DeliveryAddress from "./assets/DeliveryAddress";
import { useSelector } from "react-redux";

const OrderInfo = ({ history }) => {
  // Get State Data
  const buyer_location = useSelector((state) => state.buyer_location);
  
  const [orderStage, setOrderStage] = useState(1);
  const [mapInputVal, setMapInputVal] = useState("");
  const [location, setLocation] = useState(buyer_location.address);
  const [addressInfo, setAdressInfo] = useState("");
  // Latitude and longitude
  const [latLong, setlatLong] = useState({ lat: buyer_location.lat, lng: buyer_location.lng });

  Functions.pageInfo({
    title: "Ordapple | Order Information!",
    desc: "Order information",
  });

  const getValue = (val) => {
    setMapInputVal(val);
  };

  const updateStage = (e, back = false) => {
    console.log('Order Stage:', 'updateStage', orderStage)
    e.preventDefault();
    if(back){
      setOrderStage(orderStage-1);
    }
    else{
      setOrderStage(orderStage+1);
    }

  };
  const orderProcess = () => {
    if (orderStage === 1) {
      return (
        <GoogleMapsForm
          updateStage={updateStage}
          getValue={getValue}
          setLocation={setLocation}
          setlatLong={setlatLong}
          latLong={latLong}
          location={location}
        />
      );
    }
    else if (orderStage === 2){
      return (
        <DeliveryAddress updateStage={updateStage} setAdressInfo={setAdressInfo} />
      )
    }
    else if (orderStage === 3) {
      return (
        <OrderForm
          updateStage={updateStage}
          mapInputVal={mapInputVal}
          location={location}
          latLong={latLong}
          addressInfo={addressInfo}
          history={history}
        />
      );
    }
  };
  return (
    <>
      <ErrorBoundary>{orderProcess()}</ErrorBoundary>
    </>
  );
};

export default OrderInfo;
