import { createRef, useState } from "react";
import Form from "../../../assets/Form";
import Tags from "../../../abstracts/Tags";

const ProductInfo = (props) => {
  // Temporary tags selected by the user
  const [tempTag, setTempTag] = useState("");
  // Error message for the tags
  const [tagErr, setTagErr] = useState("");

  // Refs
  const tagsField = createRef();

  // Text highlighter algorithm
  const textHighlighter = (text) => {
    // Split the text by the hashes in it
    let textArr = text.split(/#/g);

    // Removing an added tag
    const remTag = (t) => {
      // Getting all tags except the one selected/clicked
      const nonDelArr = textArr.filter((i) => i !== t);
      props.setTags(nonDelArr.join("#"));
      textArr = nonDelArr;
    };

    // The tags selected by the user
    return textArr.map((i, ind) => {
      if (ind !== 0) {
        return (
          <span key={ind}>
            {i}{" "}
            <span
              className="delete"
              onClick={() => {
                remTag(i);
              }}
            >
              X
            </span>
          </span>
        );
      } else {
        return "";
      }
    });
  };

  // Text autocomplete algorith
  const autocomplete = (t, arr) => {
    // The tags a user is typing
    let tags = [];
    for (let i = 0; i < arr.length; i++) {
      // Getting the tags that match a user's input
      if (arr[i].substr(0, t.length).toLowerCase() === t.toLowerCase()) {
        tags.push(arr[i]);
      } else {
        // Removing the tags that don't match a user's input
        const rem = tags.filter((item) => item !== arr[i]);
        tags = rem;
      }
    }
    return tags;
  };

  // When a user selects a tag from drop down list of tags
  const selectTag = (tag) => {
    props.setTags(props.tags + ` ${tag}`);
    setTempTag("");
    tagsField.current.focus();
  };

  return (
    <Form>
      <div className="heading">
        <h1>Step 2 (Required)</h1>
      </div>

      <input
        type="text"
        className="formInput"
        name="productName"
        id="productName"
        placeholder="Product name"
        value={props.productNameVal}
        onChange={props.onChange}
        ref={props.productName}
      />
      <p style={{ color: "red" }} id={props.msg.productName && "err"}>
        {props.msg.productName}
      </p>
      <input
        type="number"
        className="formInput"
        name="productPrice"
        id="productPrice"
        placeholder="Product price"
        value={props.productPriceVal}
        onChange={props.onChange}
        ref={props.productPrice}
      />
      <p style={{ color: "red" }} id={props.msg.productPrice && "err"}>
        {props.msg.productPrice}
      </p>

      <select
        name="productCategory"
        id="productCategory"
        value={props.productCategoryVal}
        onChange={props.onChange}
        ref={props.productCategory}
      >
        <option value="">Select product category:</option>
        <option value="book">Book</option>
        <option value="fashion">Fashion</option>
        <option value="food">Food</option>
        <option value="gadget">Gadget</option>
        <option value="kitchen">Kitchen</option>
        <option value="service">Service</option>
        <option value="tech">Tech</option>
        <option value="other">Other</option>
      </select>
      <p style={{ color: "red" }} id={props.msg.productCategory && "err"}>
        {props.msg.productCategory}
      </p>

      <input
        type="text"
        className="formInput"
        name="tags"
        id="tags"
        placeholder="Tags (e.g #bags #women #fruits)"
        value={tempTag.toLowerCase()}
        autoComplete="off"
        ref={tagsField}
        onChange={(e) => {
          const { value, style } = e.target;
          const tagExp = /#/gi;
          if (!tagExp.test(value)) {
            setTagErr("Make sure you use #");
            style.border = "3px solid red";
          } else {
            setTagErr("");
            style.border = "3px solid rgb(0, 200, 0)";
            setTempTag(value);
          }
        }}
      />
      {tagErr && (
        <p style={{ color: "red" }} id="err">
          {tagErr}
        </p>
      )}

      {tempTag && (
        <div className="autocomplete">
          <ul>
            {autocomplete(tempTag.toLowerCase(), Tags).map((i, ind) => {
              return (
                <li
                  key={ind}
                  onClick={() => {
                    selectTag(i);
                  }}
                >
                  {i}
                </li>
              );
            })}
          </ul>
        </div>
      )}

      <label htmlFor="tags" style={{ border: "none" }}>
        {textHighlighter(props.tags.toLowerCase())}
      </label>
      <p style={{ color: "#bbb" }}>
        Helps customers to find your products (Optional)
      </p>

      <select
        name="currency"
        id="currency"
        value={props.currencyVal}
        onChange={props.onChange}
        ref={props.currency}
      >
        <option value="R">Currency: (R)</option>
        <option value="$">Currency: ($)</option>
      </select>
      <p style={{ color: "red" }} id={props.msg.currency && "err"}>
        {props.msg.currency}
      </p>

      <select
        name="delivery"
        id="delivery"
        value={props.deliveryVal}
        onChange={props.onChange}
        ref={props.delivery}
      >
        <option value="Yes">Eligible for Delivery: (Yes)</option>
        <option value="No">Eligible for Delivery: (No)</option>
      </select>
      <p style={{ color: "red" }} id={props.msg.delivery && "err"}>
        {props.msg.delivery}
      </p>

      <textarea
        type="text"
        className="formInput"
        name="productDescription"
        id="productDescription"
        placeholder="Product details"
        value={props.productDescriptionVal}
        onChange={props.onChange}
        ref={props.productDescription}
      ></textarea>
      <p style={{ color: "red" }} id={props.msg.productDescription && "err"}>
        {props.msg.productDescription}
      </p>

      <button
        type="submit"
        className="submit-button dry"
        onClick={(e) => {
          e.preventDefault();
          props.back();
        }}
      >
        Back
      </button>
      <button
        type="submit"
        className="submit-button"
        onClick={(e) => {
          e.preventDefault();
          props.forward();
        }}
        style={{ marginTop: "10px" }}
      >
        Next
      </button>
    </Form>
  );
};

export default ProductInfo;
