import { combineReducers } from "redux";
import Loading from "./Loading";
import Buyer from "./Buyer.js";
import Auth from "./Auth.js";
import Counter from "./Counter";
import USER from "./User.js";
import Flash from "./Flash.js";
import Admin from "./Admin";
import Employee from "./Employee";
import HOME_DATA from "./HomeData.js";
import Inbox from "./Inbox";
import Driver from "./Driver.js";
import BuyerLocationReducer from "./BuyerLocationReducer.js";

const AllReducers = combineReducers({
  // GENERAL
  counter: Counter,
  loading: Loading,
  auth: Auth,
  shops: Buyer.SHOPS,
  viewed_shop: Buyer.VIEWED_SHOP,
  searched_shops: Buyer.SEARCHED_SHOPS,
  shop_products: Buyer.SHOP_PRODUCTS,
  searched_products: Buyer.SEARCHED_PRODUCTS,
  product_preview: Buyer.PRODUCT_PREVIEW,
  cart: Buyer.CART,
  buyer_account: Buyer.BUYER_ACCOUNT,
  buyer_orders: Buyer.ORDERS_REDUCER,
  inbox: Inbox.INBOX,
  unseen_inbox: Inbox.UNSEEN_INBOX,
  user: USER,
  home_data: HOME_DATA,
  flash: Flash,
  // ADMIN
  admin_info: Admin.ADMIN_INFO,
  admin_products: Admin.PRODUCTS,
  admin_orders: Admin.ORDERS_REDUCER,
  // EMPLOYEE
  employee_users: Employee.USERS,
  employee_orders: Employee.ORDERS,
  employee_stats: Employee.STATS,
  // DRIVER
  driver_orders: Driver.ORDERS,
  driver_info: Driver.DRIVER_INFO,
  // BUYER LOCATION
  buyer_location: BuyerLocationReducer,
});

export default AllReducers;
