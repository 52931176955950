import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import AuthActions from "../redux/actions/AuthActions";
import USER from "../redux/actions/User";
import Functions from "./Functions";
import images from "./Images";

const MBLNavLinks = ({ menu, toggleMenu }) => {
  // Getting state values from redux
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);

  // Dispatch
  const dispatch = useDispatch();

  // Logging out function
  const logout = (e) => {
    e.preventDefault();
    axios({
      url: `${Functions.getAPI()}/logout`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        // Dispatching
        dispatch(AuthActions.logout());
        dispatch(USER.DELETE_ACCOUNT());
        localStorage.setItem("auth", false);
        sessionStorage.removeItem("shopsInRadius");
      })
      .catch((e) => console.log(e));
  };

  // Toggling the display of the menu links
  const menuDisplay = () => {
    if (menu) {
      return { display: "flex", zIndex: 100000 };
    } else {
      return { display: "none" };
    }
  };
  return (
    // MOBILE NAVIGATION LINKS
    <div className="nav-links" style={menuDisplay()} onClick={toggleMenu}>
      <div className="nav-links-container">
        {/* If a user is logged in */}
        <div className="user-account">
          <div className="img">
            <img src={images.avatar} alt="avatar" />
          </div>
          <div className="account-email">
            <p>{auth ? user.email : "Not logged in"}</p>
          </div>
        </div>
        <div className="nav-links-wrapper">
          <Link to="/">
            <i className="fas fa-home"></i> Home
          </Link>
          <Link to="/shops">
            <i className="fas fa-store"></i>Shops
          </Link>
          <Link to="/categories">
            <i className="fas fa-list"></i>Categories
          </Link>
          <Link to="/tags">
            <i className="fas fa-hashtag"></i>Tags / Departments
          </Link>
          {user.admin && (
            <Link to="/admin/account">
              <i className="fas fa-table"></i> Admin
            </Link>
          )}
          {user.driver && (
            <Link to="/driver/manage-orders">
              <i className="fas fa-taxi"></i> Driver
            </Link>
          )}
          <Link to="/buyer/edit-account">
            <i className="fas fa-pencil"></i> Edit account
          </Link>
          <Link to="/help">
            {" "}
            <i className="fas fa-question"></i> FAQ
          </Link>
          {/* If a user is an employee */}
          {user.employee && (
            <Link to="/employee/mail">
              <i className="fas fa-briefcase"></i> Employee
            </Link>
          )}
          <Link to="/about">
            {" "}
            <i className="fas fa-info"></i> About
          </Link>
          <Link to="/contact">
            {" "}
            <i className="fas fa-phone"></i> Contact us
          </Link>
          {!auth && (
            <Link to="/login">
              {" "}
              <i className="fa fa-sign-in"></i> Login
            </Link>
          )}
          {auth && (
            <Link to="/logout" onClick={logout}>
              {" "}
              <i className="fa fa-sign-out"></i> Logout
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default MBLNavLinks;
