import images from "../abstracts/Images";
import FollowWhs from "../components/admin/assets/FollowWhs";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import AuthActions from "../redux/actions/AuthActions";
import USER from "../redux/actions/User";
import Functions from "../abstracts/Functions";
import axios from "axios";

const AccountInfo = ({
  buyer,
  toggleWhsDisplay,
  img,
  name,
  email,
  followers,
  uploadingImage,
  togglePopupDisplay,
  shopVerified,
}) => {
  const path = useLocation().pathname;

  // Dispatch
  const dispatch = useDispatch();

  const logout = (e) => {
    e.preventDefault();
    axios({
      url: `${Functions.getAPI()}/logout`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        // Dispatching
        dispatch(AuthActions.logout());
        dispatch(USER.DELETE_ACCOUNT());
        localStorage.setItem("auth", false);
        sessionStorage.removeItem("shopsInRadius");
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          console.log(res.data);
        } else {
          console.log(e);
        }
      });
  };

  return (
    <div
      className={
        buyer
          ? "account-info-container buyer-account"
          : "account-info-container"
      }
    >
      <div className="info-img">
        {!buyer ? (
          img && img.Location ? (
            <img src={img.Location} alt="user" />
          ) : (
            <img src={images.avatar} alt="avatar" />
          )
        ) : (
          <img src={images.avatar} alt="avatar" />
        )}
        {/* For the admin acc page */}
        {!buyer && (
          <div className="upload-btn">
            <span>
              <input
                type="file"
                onChange={uploadingImage}
                name="profile-pic"
                id="profile-pic"
              />
              <label htmlFor="profile-pic" onClick={togglePopupDisplay}>
                <i className="fa fa-camera" aria-hidden="true">
                  {" "}
                </i>
              </label>
            </span>
          </div>
        )}
      </div>
      <div className="info-text">
        <h2>
          {name}{" "}
          {!buyer && shopVerified && <i className="fas fa-check verified"></i>}
        </h2>
        <h3>{email}</h3>

        {!buyer && (
          <FollowWhs
            foll={Functions.numFormatter(followers.length)}
            toggleWhsDisplay={toggleWhsDisplay}
          />
        )}
      </div>
      <div className="line"></div>
      <Link to={buyer ? "/buyer/edit-account" : "/admin/edit-account"}>
        Edit Account
      </Link>
      {path === "/admin/account" ? (
        <Link className="logout" to="/admin/stats">
          Transactions
        </Link>
      ) : (
        <Link className="logout" to="/logout" onClick={logout}>
          Logout
        </Link>
      )}
    </div>
  );
};

export default AccountInfo;
