export default function ShoppingProcessCard({steps}){
    return (
        <div className="shopping-process-container">
            {steps && steps.map((i, ind) =>(
                <div key={ind} className="shopping-process-card">
                    <div className="step">{i.step}</div>
                    <div className="desc">{i.desc}</div>
                    <div className="line"></div>
                </div>
            ))}
        </div>
    )
}