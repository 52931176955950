import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FLASH, { RESTORE_FLASH } from "../../../redux/actions/Flash";
import Messages from "../../../abstracts/Messages";
import mapData from "../../../abstracts/Provs-Coutries";
import { Link } from "react-router-dom";

const DeliveryAddress = ({ updateStage, setAdressInfo }) => {
  // Dispatch
  const dispatch = useDispatch();

  // use state
  const [street, setStreet] = useState("");
  const [building, setBuilding] = useState("");
  const [suburb, setSuburb] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("South Africa");

  const [msg, setMsg] = useState({});
  const flash = useSelector((state) => state.flash);

  // Use effect hook
  useEffect(() => {
    // Restoring the flash messages
    dispatch(RESTORE_FLASH());
  }, [dispatch]);

  const onChange = (e) => {
    const { name, value, style } = e.target;

    if (name === "street") {
      setStreet(value);
      if (value === "") {
        style.border = "3px solid red";
        setMsg({ street: "Enter street/Unit/Zone/Stand no" });
      } else {
        setMsg({ street: "" });
        style.border = "3px solid rgb(0, 200, 0)";
      }
    } else if (name === "building") {
      setBuilding(value);
      style.border = "3px solid rgb(0, 200, 0)";
    } else if (name === "suburb") {
      setSuburb(value);
      if (value === "") {
        style.border = "3px solid red";
        setMsg({ suburb: "Select a suburb" });
      } else {
        setMsg({ suburb: "" });
        style.border = "3px solid rgb(0, 200, 0)";
      }
    } else if (name === "city") {
      setCity(value);
      if (value === "") {
        style.border = "3px solid red";
        setMsg({ city: "Select a city" });
      } else {
        setMsg({ city: "" });
        style.border = "3px solid rgb(0, 200, 0)";
      }
    } else if (name === "province") {
      setProvince(value);
      if (value === "") {
        style.border = "3px solid red";
        setMsg({ province: "Select a province" });
      } else {
        setMsg({ province: "" });
        style.border = "3px solid rgb(0, 200, 0)";
      }
    } else if (name === "postalCode") {
      setPostalCode(value);
      if (value.length !== 4) {
        style.border = "3px solid red";
        setMsg({ postalCode: "Must be 4 digits" });
      } else {
        setMsg({ postalCode: "" });
        style.border = "3px solid rgb(0, 200, 0)";
      }
    } else if (name === "country") {
      setCountry(value);
      if (value === "") {
        style.border = "3px solid red";
        setMsg({ country: "Select a country" });
      } else {
        setMsg({ country: "" });
        style.border = "3px solid rgb(0, 200, 0)";
      }
    }
  };

  // Order function
  const submitAddress = (e) => {
    e.preventDefault();

    // Restoring the flash messages
    dispatch(RESTORE_FLASH());

    if (
      street === "" ||
      suburb === "" ||
      city === "" ||
      province === "" ||
      country === "" ||
      postalCode === ""
    ) {
      dispatch(FLASH({ warn: "Incomplete fields!", err: "", success: "" }));
    } else if (postalCode.length !== 4) {
      dispatch(
        FLASH({
          err: "",
          success: "",
          warn: "A field(s) is filled incorrectly!",
        })
      );

      setMsg({ postalCode: "Must be 4 digits" });
    } else if (country === "") {
      dispatch(
        FLASH({
          err: "",
          success: "",
          warn: "A field(s) is filled incorrectly!",
        })
      );
      setMsg({ country: "Select a country!" });
    } else if (province === "") {
      dispatch(
        FLASH({
          err: "",
          success: "",
          warn: "A field(s) is filled incorrectly!",
        })
      );
      setMsg({ province: "Select a province!" });
    } else {
      updateStage(e);
      setAdressInfo(
        `${street}, ${
          building !== "" ? building + "," : ""
        } ${suburb}, ${city}, ${province}, ${postalCode}, ${country}`
      );
    }
  };

  return (
    <div style={{ display: "flex" }} className="register-login login ">
      <div className="form">
        <div className="heading">
          <h1>Step 2 (Delivery Address)</h1>
        </div>

        {/* Flash messages */}
        {flash.err !== "" && <Messages err={flash.err} />}
        {flash.warn !== "" && <Messages warn={flash.warn} />}

        {/* Form */}
        <form className="form-slide" method="POST">
          <input
            required
            type="text"
            className="formInput"
            name="street"
            placeholder="Street/Unit/Zone/Stand no"
            value={street}
            onChange={onChange}
          />
          <p style={{ color: "red" }} id="address-error">
            {msg.street}
          </p>

          <input
            required
            type="text"
            className="formInput"
            name="building"
            placeholder="Building/Apartment"
            value={building}
            onChange={onChange}
          />
          <p style={{ color: "red" }} id="address-error">
            {msg.building}
          </p>

          <input
            required
            type="text"
            className="formInput"
            name="suburb"
            placeholder="Suburb"
            value={suburb}
            onChange={onChange}
          />
          <p style={{ color: "red" }} id="address-error">
            {msg.suburb}
          </p>

          <input
            required
            type="text"
            className="formInput"
            name="city"
            placeholder="City"
            value={city}
            onChange={onChange}
          />
          <p style={{ color: "red" }} id="address-error">
            {msg.city}
          </p>

          <input
            required
            type="text"
            className="formInput"
            name="postalCode"
            placeholder="Postal code"
            value={postalCode}
            onChange={onChange}
          />
          <p style={{ color: "red" }} id="address-error">
            {msg.postalCode}
          </p>

          <div className="passwords">
            <select
              name="province"
              value={province}
              id="province"
              onChange={onChange}
            >
              <option value="">(Select province)</option>
              {mapData.provinces.map((i, ind) => (
                <option key={ind} value={i}>
                  {i}
                </option>
              ))}
            </select>
          </div>
          <p style={{ color: "red" }} id="address-error">
            {msg.province}
          </p>

          <div className="passwords">
            <select
              name="country"
              value={country}
              id="country"
              onChange={onChange}
            >
              <option value="">(Select country)</option>
              {mapData.countries.map((i, ind) => (
                <option key={ind} value={i}>
                  {i}
                </option>
              ))}
            </select>
          </div>
          <p style={{ color: "red" }} id="address-error">
            {msg.country}
          </p>

          <button id="next" onClick={submitAddress}>
            Next
          </button>
          <br />

          <Link
            style={{ width: "100%", display: "inline", textAlign: "center" }}
            id="back"
            to="/order-info"
            onClick={(e)=>{
              updateStage(e, true)
            }}
          >
            <i style={{ marginRight: "5px" }} className="fas fa-arrow-left"></i>{" "}
            Back
          </Link>
        </form>
      </div>
    </div>
  );
};

export default DeliveryAddress;
