import { useSelector } from "react-redux";
import BuyerActions from "../redux/actions/BuyerActions";
import { Link, useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import FLASH from "../redux/actions/Flash";
import Popup from "./Popup";
import AdminActions from "../redux/actions/AdminActions";
import Functions from "../abstracts/Functions";
import TimeAgo from "react-timeago";
import { useState, useEffect } from "react";
import Employee from "../redux/actions/Employee";
import Driver from "../redux/actions/DriverActions";

const OrdersCard = ({
  buyer,
  display,
  setDisplay,
  dispatch,
  orderNumber,
  orderId = null,
  customerName,
  customerEmail,
  customerPhone,
  customerAddress,
  customerLocation,
  pickUpTime,
  dateOrdered,
  elegibleForDelivery,
  itemsInOrder,
  totalPrice,
  status,
  trackingInfo,
  _id,
  shopId,
  shopName,
  agentCode,
}) => {
  // Getting values from redux state
  const driver_info = useSelector((state) => state.driver_info);
  const flash = useSelector((state) => state.flash);
  const path = useLocation().pathname;
  const history = useHistory();

  // Use state
  const [statusVal, setStatusVal] = useState("");
  const [trackingInfoVal, setTrackingInfoVal] = useState([]);
  const [trackingInfoOptions, setTrackingInfoOptions] = useState([
    "order received",
    "preparing",
    "driver found",
    "on the way",
    "delivered",
  ]);

  // Use effect
  useEffect(() => {
    setStatusVal(status);
    console.log("trackingInfo:", trackingInfo);
    if (trackingInfo) {
      setTrackingInfoVal(trackingInfo);
    }
  }, []);

  // update the status
  const updateStatus = (e, status) => {
    e.preventDefault();
    setDisplay(true);

    const req = () => {
      axios({
        url: `${Functions.getAPI()}/admin/orders/update/${orderNumber}`,
        method: "POST",
        withCredentials: true,
        data: { status },
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          console.log("order update:", data);
          // Dispatching
          dispatch(FLASH({ err: "", success: data.success, warn: "" }));
        })
        .catch((err) => {
          const res = err.response;
          if (res) {
            console.log(res.data);
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(err);
          }
        });
    };

    setStatusVal(status);
    req(status);
  };

  // update the tracking info
  const updateTrackingInfo = (e, trackingInfo) => {
    e.preventDefault();
    setDisplay(true);

    const req = () => {
      axios({
        url: `${Functions.getAPI()}/order/tracking-info/`,
        method: "POST",
        withCredentials: true,
        data: { orderNumber, customerEmail, shopId, trackingInfo },
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          console.log("order update:", data);
          // Dispatching
          dispatch(FLASH({ err: "", success: data.success, warn: "" }));
        })
        .catch((err) => {
          const res = err.response;
          if (res) {
            console.log(res.data);
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(err);
          }
        });
    };

    let ti = [...trackingInfoVal, trackingInfo.toLowerCase()];
    ti = ti.filter(Functions.uniqueArr);
    setTrackingInfoVal(ti);
    req(trackingInfo);
  };

  // Paying
  const pay = (e, on) => {
    e.preventDefault();
    history.push("/order/" + on);
  };

  // Deleting orders

  const deleteOrder = (e) => {
    e.preventDefault();
    setDisplay(true);

    if (buyer) {
      axios({
        url: `${Functions.getAPI()}/orders/delete/${orderNumber}`,
        method: "POST",
        withCredentials: true,
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          dispatch(FLASH({ success: data.success, warn: "", err: "" }));
          dispatch(BuyerActions.BUYER_REMOVE_ORDER(orderNumber));
        })
        .catch((err) => {
          const res = err.response;
          if (res) {
            console.log(res.data);
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(err);
          }
        });
    } else {
      axios({
        url: `${Functions.getAPI()}/employee/order/delete/${shopId}/${_id}`,
        method: "POST",
        withCredentials: true,
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          // Dispatching
          dispatch(FLASH({ success: data.success, warn: "", err: "" }));
          dispatch(Employee.DELETE_ORDER(_id));
        })
        .catch((err) => {
          const res = err.response;
          if (res) {
            console.log(res.data);
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(err);
          }
        });
    }
  };

  // Accept the delivery to deliver the order
  const acceptDelivey = (e, orderId) => {
    e.preventDefault();

    // Request url
    const url = `${Functions.getAPI()}/driver/accept-delivery`;

    axios({
      url: url,
      method: "POST",
      withCredentials: true,
      data: { orderId, orderNumber, shopId, customerEmail },
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        // Dispatching
        dispatch(FLASH({ err: "", success: data.success, warn: "" }));
        dispatch(Driver.ACCEPT_ORDER(orderId));
      })
      .catch((err) => {
        const res = err.response;
        if (res) {
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
        } else {
          console.log(err);
        }
      });
  };

  // get directions to the destination/location of the user who ordered
  const getDirections = (e, location) => {
    e.preventDefault();
    console.log("Customer location:", location);
    const a = document.createElement("a");
    a.href = `https://www.google.com/maps/dir/?api=1&destination=${location.latLng.lat},${location.latLng.lng}`;
    a.target = "_blank";
    a.click();
  };

  // Render orders
  const renderOrders = () => {
    const renderImage = (item) => {
      if (item.productImage !== "") {
        return <img src={item.productImage} alt="Product" />;
      } else {
        return <p style={{ color: "#bbb" }}>No image</p>;
      }
    };
    // Rendering options
    const renderOptions = (item) => {
      // All options
      const k = Object.keys(item.options);
      const v = Object.values(item.options);

      return k.map((i, ind) => {
        return (
          <p key={ind}>
            <strong>{i}:</strong> {v[ind]}
          </p>
        );
      });
    };

    // Render item status class
    const renderItemStatusClass = (s) => {
      if (s === "pending payment") {
        return "pp";
      } else if (
        s === "pending delivery" ||
        s === "sent to courier" ||
        s === "pending delivery"
      ) {
        return "pd";
      } else {
        return "cmp";
      }
    };

    return (
      <div className={buyer ? "card buyer-card" : "card"}>
        <h2>
          Order Number: <span> {orderNumber} </span>
        </h2>

        <div className="heading">
          <h2>Order Details:</h2>
        </div>

        <div className="order-details">
          {shopName && (
            <p>
              <span>Shop:</span> {shopName}
            </p>
          )}
          <p>
            <span>Name:</span> {customerName}
          </p>
          <p>
            <span>E-mail:</span> {customerEmail}
          </p>
          <p>
            <span>Phone:</span> {customerPhone}
          </p>
          <p
            onClick={(e) => {
              getDirections(e, customerLocation);
            }}
          >
            <span>Address:</span>{" "}
            <span className="order-address">{customerAddress}</span>
          </p>
          {pickUpTime && (
            <p>
              <span>Pick-up time:</span> {pickUpTime}
            </p>
          )}

          <p>
            <span>Date of Order:</span> <TimeAgo date={Number(dateOrdered)} />
          </p>
          <p>
            <span>Elegible for delivery:</span> {elegibleForDelivery}
          </p>
          {agentCode && (
            <p>
              <span>Agent code:</span> {agentCode}
            </p>
          )}
        </div>

        <div className="heading">
          <h2>Items in this order:</h2>
        </div>

        <div className="items">
          {itemsInOrder.map((item) => (
            <div key={item._id}>
              <Link
                key={item._id}
                to={`/prod/${item.productName}/${item.productId}/${item.shopId}`}
                className="items-card"
                style={{ position: "relative" }}
              >
                <div className="card-img">{renderImage(item)}</div>
                <div className="card-text">
                  <h1>{item.productName}</h1>
                  <p>{item.shopName}</p>
                  <p className="price">{`${item.productCurrency}${item.productPrice}`}</p>
                </div>
                <div className="qty">
                  <p>[{item.qty}]</p>
                </div>
                {!path.match("/admin") && (
                  <div
                    className={`istatus ${renderItemStatusClass(item.status)}`}
                  >
                    {item.status}
                  </div>
                )}
              </Link>
              {item.options && (
                <div
                  style={{
                    padding: "10px 0px",
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  {renderOptions(item)}
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Show order status */}
        {path.match("/admin") && (
          <div className="heading">
            <h2>
              Status:{" "}
              <span className="orderStatus">
                {statusVal.toLocaleUpperCase()}
              </span>
            </h2>
          </div>
        )}
        {path.match("/driver") && (
          <div className="heading">
            <h2>
              Status:{" "}
              <span className="orderStatus">
                {statusVal.toLocaleUpperCase()}
              </span>
            </h2>
          </div>
        )}

        {/* Only for admins */}
        {path.match("/admin") && (
          <div
            className={`status-select ${
              statusVal === "pending payment" && "deactivate"
            }`}
          >
            <div
              className={`status-option ${
                statusVal === "pending delivery" && "active"
              }`}
              onClick={(e) => {
                updateStatus(e, "pending delivery");
              }}
            >
              Pending delivery
            </div>
            <div
              className={`status-option ${
                statusVal === "sent to courier" && "active"
              }`}
              onClick={(e) => {
                updateStatus(e, "sent to courier");
              }}
            >
              Sent to courier
            </div>
            <div
              className={`status-option ${
                statusVal === "out for delivery" && "active"
              }`}
              onClick={(e) => {
                updateStatus(e, "out for delivery");
              }}
            >
              Out for delivery
            </div>
            <div
              className={`status-option ${
                statusVal === "delivered" && "active"
              }`}
              onClick={(e) => {
                updateStatus(e, "delivered");
              }}
            >
              Delivered
            </div>
          </div>
        )}

        {/* Tracking info */}
        <div className="heading">
          <h2>Tracking Info:</h2>

          <div className="order-ti">
            {trackingInfoVal.map((ti, ind) => (
              <div
                className={`ti ${
                  ind === trackingInfoVal.length - 1 ? "active" : "not-active"
                }`}
                key={ind}
              >
                <div className="dot">
                  <div></div>
                </div>
                <div className="ti-text">{ti}</div>
              </div>
            ))}
          </div>
        </div>

        {/* Only for drivers and admins */}
        {!path.match("/buyer") && (
          <div className={`status-select`}>
            {trackingInfoOptions.map((ti, ind) => (
              <div
                key={ind}
                className={`status-option ${
                  ti === trackingInfoVal[trackingInfoVal.length - 1] && "active"
                }`}
                onClick={(e) => {
                  updateTrackingInfo(e, ti);
                }}
              >
                {ti}
              </div>
            ))}
          </div>
        )}

        <div className="heading">
          <h2>Total:</h2>
        </div>

        <h3 className="total">R{totalPrice}</h3>

        <form method="POST">
          {!path.match("/admin") && status === "pending payment" && (
            <button
              onClick={(e) => {
                pay(e, orderNumber);
              }}
              className="pay"
              type="submit"
            >
              Pay now
            </button>
          )}
          {!path.match("/admin") && !path.match("/driver") && (
            <button
              onClick={(e) => {
                deleteOrder(e);
              }}
              className="delete"
              type="submit"
            >
              Delete
            </button>
          )}
          {path.match("/driver") &&
            orderId &&
            !driver_info.orders.includes(orderId) && (
              <button
                onClick={(e) => {
                  acceptDelivey(e, orderId);
                }}
                className="pay"
                type="submit"
              >
                Accept delivery
              </button>
            )}
        </form>
      </div>
    );
  };
  return (
    //   If orders are found
    <>
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}
      {renderOrders()}
    </>
  );
};

export default OrdersCard;
