import { Link } from "react-router-dom";

const JobNav = ({ page }) => {
  return (
    <div className="admin-nav">
      <div className="nav-container">
        <Link to="/employee/mail" className={page === "mail" ? "active" : ""}>
          Mail
        </Link>
        <Link to="/employee/users" className={page === "users" ? "active" : ""}>
          Users
        </Link>
        <Link to="/employee/stats" className={page === "stats" ? "active" : ""}>
          Stats
        </Link>
      </div>
    </div>
  );
};

export default JobNav;
