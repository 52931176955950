import { Link } from "react-router-dom";

const PricingCard = ({ sub }) => {
  return (
    <div className="card">
      <h2>{sub.name}</h2>
      <p className="price">{sub.price}</p>
      <p className="includes">Includes:</p>
      <ul>
        {sub.includes.map((i, ind) => (
          <li key={ind}>{i}</li>
        ))}
      </ul>
      <Link to={sub.url}>Get started</Link>
    </div>
  );
};

export default PricingCard;
