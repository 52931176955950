// Set the initial state
const setInitialState = () => {
  const buyer_location = JSON.parse(localStorage.getItem("BUYER_LOCATION"));
  let initialState = { lng: 29.4486, lat: -23.8962, address: "" };

  if (buyer_location) {
    initialState.lat = buyer_location.lat;
    initialState.lng = buyer_location.lng;
    initialState.address = buyer_location.address;
  }

  return initialState;
};

const BuyerLocationReducer = (state = setInitialState(), { type, payload }) => {
  switch (type) {
    case "SET_BUYER_LOCATION_LATLONG_ACTION":
      localStorage.setItem(
        "BUYER_LOCATION",
        JSON.stringify({
          ...state,
          lat: payload.lat,
          lng: payload.lng,
        })
      );
      return {
        ...state,
        lat: payload.lat,
        lng: payload.lng,
      };
    case "SET_BUYER_LOCATION_ADDRESS_ACTION":
      localStorage.setItem(
        "BUYER_LOCATION",
        JSON.stringify({
          ...state,
          address: payload,
        })
      );
      return {
        ...state,
        address: payload,
      };
    default:
      return state;
  }
};
export default BuyerLocationReducer;
