import { createRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminActions from "../../redux/actions/AdminActions";
import FLASH, { RESTORE_FLASH } from "../../redux/actions/Flash";
import axios from "axios";
import Popup from "../../assets/Popup";
import Form from "../../assets/Form";
import Functions from "../../abstracts/Functions";
import GoogleMapComponent from "../../assets/GoogleMapComponent";
import GoogleSearchPlaces from "../../assets/GoogleSearchPlaces";

const DriverRegister = ({ history }) => {
  // Redux state
  const admin_info = useSelector((state) => state.admin_info);
  const user = useSelector((state) => state.user);
  const flash = useSelector((state) => state.flash);

  Functions.pageInfo({
    title: "Ordapple | Edit Your Admin Account",
    desc: "Editing Your Admin Account",
  });

  // Redux disparch
  const dispatch = useDispatch();

  // Use state
  const [display, setDisplay] = useState(true);
  const [shopNameVal, setShopNameVal] = useState(
    admin_info.name ? admin_info.name : ""
  );
  const [adminNameVal, setAdminNameVal] = useState(
    admin_info.adminName ? admin_info.adminName : ""
  );

  const [aboutDriverVal, setAboutDriverVal] = useState("");
  const [vehicleNameVal, setVehicleNameVal] = useState("");
  const [vehicleColorVal, setVehicleColorVal] = useState("");
  const [vehicleNumberPlateVal, setVehicleNumberPlateVal] = useState("");

  const [locationVal, setLocationVal] = useState(
    admin_info.location ? admin_info.location : ""
  );

  const [stage, setStage] = useState(1);
  const [place, setPlace] = useState(
    admin_info.streetAddress ? admin_info.streetAddress.place : ""
  );
  // Latitude and longitude
  const [latLong, setlatLong] = useState(
    admin_info.streetAddress
      ? admin_info.streetAddress.latLng
      : { lat: null, lng: null }
  );

  // Messages
  const [msg, setMsg] = useState({});

  // Refs
  const aboutDriver = createRef();
  const vehicleName = createRef();
  const vehicleColor = createRef();
  const vehicleNumberPlate = createRef();

  // Use effect
  useEffect(() => {
    dispatch(RESTORE_FLASH());
  }, [dispatch]);

  const onChange = (e) => {
    const { value, name, style } = e.target;

    if (name === "aboutDriver") {
      setAboutDriverVal(value);
      setMsg({ aboutDriver: "" });
      style.border = "3px solid rgb(0, 200, 0)";
    } else if (name === "vehicleName") {
      setVehicleNameVal(value);
      setMsg({ vehicleName: "" });
      style.border = "3px solid rgb(0, 200, 0)";
    } else if (name === "vehicleColor") {
      setVehicleColorVal(value);
      setMsg({ vehicleColor: "" });
      style.border = "3px solid rgb(0, 200, 0)";
    } else if (name === "vehicleNumberPlate") {
      setVehicleNumberPlateVal(value);
      setMsg({ vehicleNumberPlate: "" });
      style.border = "3px solid rgb(0, 200, 0)";
    }
  };

  // Register as driver
  const submit = (e) => {
    e.preventDefault();

    if (vehicleName.current.value.trim() === "") {
      setMsg({ vehicleName: "Required field!" });
    } else if (vehicleColor.current.value.trim() === "") {
      setMsg({ vehicleColor: "Required field!" });
    } else if (vehicleNumberPlate.current.value.trim() === "") {
      setMsg({ vehicleNumberPlate: "Required field!" });
    } else {
      setDisplay(true);

      // Loading animation on submit button
      e.target.classList.add("btn-animate");
      e.target.innerText = "Processing...";

      const aboutDriver = aboutDriverVal;
      const vehicleName = vehicleNameVal;
      const vehicleColor = vehicleColorVal;
      const vehicleNumberPlate = vehicleNumberPlateVal;

      // Request url
      const url = `${Functions.getAPI()}/driver/register`;

      const data = {
        aboutDriver,
        vehicleName,
        vehicleColor,
        vehicleNumberPlate,
      };

      axios({
        url: url,
        method: "POST",
        withCredentials: true,
        data,
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          // Removing the loading animation from the button
          e.target.classList.remove("btn-animate");
          e.target.innerText = "Submit";

          // Dispatching
          dispatch(FLASH({ err: "", success: data.success, warn: "" }));
          //   dispatch(AdminActions.EDIT_ACCOUNT(data.data));
        })
        .catch((err) => {
          // Removing the loading animation from the button
          e.target.classList.remove("btn-animate");
          e.target.innerText = "Submit";

          const res = err.response;
          if (res) {
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(err);
          }
        });

      setMsg({});
    }
  };

  return (
    <div>
      {flash.err !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          err={flash.err}
          history={history}
        />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
          history={history}
        />
      )}
      {flash.warn !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          warn={flash.warn}
          history={history}
        />
      )}

      <Form>
        <div className="heading">
          <h1>Driver Registration</h1>
        </div>

        <>
          <input
            type="text"
            className="formInput"
            name="aboutDriver"
            id="aboutDriver"
            placeholder="Additional info about you"
            value={aboutDriverVal}
            onChange={onChange}
            ref={aboutDriver}
          />
          <p style={{ color: "red" }} id={msg.aboutDriver && "err"}>
            {msg.aboutDriver}
          </p>

          <input
            type="text"
            className="formInput"
            name="vehicleName"
            id="vehicleName"
            placeholder="Vehicle name"
            value={vehicleNameVal}
            onChange={onChange}
            ref={vehicleName}
          />
          <p style={{ color: "red" }} id={msg.vehicleName && "err"}>
            {msg.vehicleName}
          </p>

          <input
            type="text"
            className="formInput"
            name="vehicleColor"
            id="vehicleColor"
            placeholder="Vehicle color"
            value={vehicleColorVal}
            onChange={onChange}
            ref={vehicleColor}
          />
          <p style={{ color: "red" }} id={msg.vehicleColor && "err"}>
            {msg.vehicleColor}
          </p>

          <input
            type="text"
            className="formInput"
            name="vehicleNumberPlate"
            id="vehicleNumberPlate"
            placeholder="Vehicle number plate"
            value={vehicleNumberPlateVal}
            onChange={onChange}
            ref={vehicleNumberPlate}
          />
          <p style={{ color: "red" }} id={msg.vehicleNumberPlate && "err"}>
            {msg.vehicleNumberPlate}
          </p>

          <button type="submit" className="submit-button" onClick={submit}>
            Submit
          </button>
        </>
      </Form>
    </div>
  );
};

export default DriverRegister;
