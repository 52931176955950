const GET_ORDERS = (data) => {
  return {
    type: "GET_ORDERS",
    payload: data,
  };
};
const GET_DRIVER_INFO = (data) => {
  return {
    type: "GET_DRIVER_INFO",
    payload: data,
  };
};
const ACCEPT_ORDER = (data) => {
  return {
    type: "ACCEPT_ORDER",
    payload: data,
  };
};

const Driver = {
  GET_ORDERS,
  GET_DRIVER_INFO,
  ACCEPT_ORDER,
};

export default Driver;
