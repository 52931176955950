const SET_BUYER_LOCATION_LATLONG_ACTION = (payload) => {
  return {
    type: "SET_BUYER_LOCATION_LATLONG_ACTION",
    payload: payload,
  };
};

const SET_BUYER_LOCATION_ADDRESS_ACTION = (payload) => {
  return {
    type: "SET_BUYER_LOCATION_ADDRESS_ACTION",
    payload: payload,
  };
};

export default {
  SET_BUYER_LOCATION_ADDRESS_ACTION,
  SET_BUYER_LOCATION_LATLONG_ACTION,
};
