import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Loader from "../abstracts/Loader";
import BuyerLocationActions from "../redux/actions/BuyerLocationActions";
import { useDispatch } from "react-redux";

export default function GoogleSearchPlaces({
  inputPlaceholder,
  getValue,
  setLocation,
  setlatLong,
  location,
  onHandleSelectPlace,
  inputRef,
}) {
  // Address/location/place found
  const place = location;

  const dispatch = useDispatch();

  // getting the updated address when a user types
  const handleChange = (address) => {
    if (getValue) {
      getValue(address);
    }
    if (setLocation) {
      setLocation(address);
    }
  };

  // when a user selects 1 option from the suggestions
  const handleSelect = async (address) => {
    await geocodeByAddress(address)
      .then((results) => {
        const placeName = results[0].formatted_address;
        console.log(placeName);
        dispatch(
          BuyerLocationActions.SET_BUYER_LOCATION_ADDRESS_ACTION(placeName)
        );
        setLocation(placeName);
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        dispatch(
          BuyerLocationActions.SET_BUYER_LOCATION_LATLONG_ACTION({
            lat: latLng.lat,
            lng: latLng.lng,
          })
        );
        setlatLong({ lat: latLng.lat, lng: latLng.lng });
      })
      .catch((error) => console.error("Error", error));

    // Calculate distance between two locations
    // const from = 'Polokwane';

    // axios({
    //     url: `${Functions.getAPI()}/location/calc/?from=${from}&to=${address}`,
    //     method: "GET",
    // })
    //     .then(async (res) => {
    //     // Waiting for the data before proceeding
    //     const data = await res.data;

    //     console.log("Distance Calculation:", 'HandleSelect in GoogleMaps', data);
    //     })
    //     .catch((err) => {
    //     const res = err.response;
    //     console.log('Error:', res)
    //     })

    if (onHandleSelectPlace) {
      await onHandleSelectPlace();
    }
  };

  const loaderStyles = {
    display: "flex",
    width: "100%",
    padding: "3px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
  };

  const inputStyles = {
    position: "relative",
    width: "100%",
    height: "100%",
  };

  return (
    <PlacesAutocomplete
      value={place}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          {/* Search box for places */}
          <div className="search-container">
            <i className="fas fa-search search-icon"></i>
            <input
              {...getInputProps({
                placeholder: inputPlaceholder
                  ? inputPlaceholder
                  : "Search Places ...",
                className: "location-search-input",
                id: "location",
                style: inputStyles,
                ref: inputRef,
              })}
            />
          </div>
          <div className="autocomplete-dropdown-container">
            {/* When the places are loading */}
            {loading && (
              <div style={loaderStyles}>
                <Loader />
              </div>
            )}
            {/* Displayed suggestions as you type */}
            {suggestions.map((suggestion, i) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: "#eee", cursor: "pointer", padding: "5px" }
                : {
                    backgroundColor: "#ffffff",
                    cursor: "pointer",
                    padding: "5px",
                  };
              return (
                <div
                  key={i}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  {/* The description contains the place's name */}
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}
