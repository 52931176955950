import images from "../../abstracts/Images";
import GoogleSearchPlaces from "../../assets/GoogleSearchPlaces";
import ShoppingProcessCard from "./assets/ShoppingProcessCard";

const Hero = ({ setLocation, location, setlatLong, history }) => {
  const shoppingProcessStepsArray = [
    { step: 1, desc: "Enter Your Address To Find Restaurants Near You" },
    { step: 2, desc: "Add Items To Your Card And Checkout" },
    { step: 3, desc: "Wait For Your Delivery OR Go To Collect It" },
  ];

  const onHandleSelectPlace = () => {
    window.location.assign("/shop");
  };

  return (
    <>
      <div className="imgs">
        {/* {images.banners.slides.map((img, ind) => (
        <img key={ind} src={img} alt="Banner" loading="lazy" />
      ))} */}
        <img src={images.banners.hero} alt="Banner" loading="lazy" />
      </div>

      <div className="user-location">
        <div className="glass-container">
          {/* <p>Find Restaurants Near You</p> */}
          <GoogleSearchPlaces
            inputPlaceholder="Enter your street address here"
            setLocation={setLocation}
            setlatLong={setlatLong}
            location={location}
            onHandleSelectPlace={onHandleSelectPlace}
          />
        </div>
      </div>

      <ShoppingProcessCard steps={shoppingProcessStepsArray} />
    </>
  );
};

export default Hero;
