import images from "./Images";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Functions from "./Functions";

const DesktopNav = (props) => {
  // Accessing state
  const user = useSelector((state) => state.user);
  const cart = useSelector((state) => state.cart);
  const viewed_shop = useSelector((state) => state.viewed_shop);
  const unseen_inbox = useSelector((state) => state.unseen_inbox);

  const path = useLocation().pathname;

  return (
    // Desktop Navigation
    <div className="desktop-nav landing">
      <div className="logo">
        <Link to="/">
          <img src={images.logos.logo512} alt="logo" />
          <span className="app-name">Ordapple</span>
        </Link>
      </div>
      <div className="links">
        <ul className="desktop-list">
          {/* If a user is logged in */}
          <li>
            <Link to="/buyer/account">My account</Link>
          </li>
          <li>
            {user && user.driver && (
              <Link to="/driver/manage-orders">Driver</Link>
            )}
            {user && user.admin && <Link to="/admin/account">Admin</Link>}
          </li>
          <li>
            <Link to="/buyer/orders">Orders</Link>
          </li>
          <li>
            <Link to="/shop" onClick={props.togglePopupOpts}>
              Shop
            </Link>
          </li>
          <li>
            {user ? (
              <Link className="desktop-link" to="/inbox">
                Inbox{" "}
                {unseen_inbox.seen === false && (
                  <span className="inbox">
                    {Functions.numFormatter(unseen_inbox.num)}
                  </span>
                )}
              </Link>
            ) : (
              <Link className="desktop-link" to="/help">
                FAQ
              </Link>
            )}
          </li>
          {path === "/shops" ||
          path === "/shop" ||
          path === "/products/search" ||
          path === "/shops/search" ||
          path === "/" ||
          path.match("/category") ||
          path.match("/department") ||
          path === `/shop/${viewed_shop._id}` ||
          path === `/${viewed_shop._id}/products-results` ? (
            <li>
              <Link
                className="search"
                to="/search"
                onClick={props.toggleDesktopSearch}
              >
                <i className="fas fa-search"></i>
              </Link>
            </li>
          ) : (
            ""
          )}

          <li>
            <Link to="/cart" className="login-navLink">
              <i className="fas fa-shopping-bag"></i>
              <span className="cart-items-num">{cart.items.length}</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DesktopNav;
