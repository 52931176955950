// ORDERS
const ORDERS = (state = [], { type, payload }) => {
  switch (type) {
    case "GET_ORDERS":
      return payload;
    case "DELETE_ORDER":
      const updatedOrders = state.filter((el, ind) => el._id !== payload);
      return updatedOrders;
    case "DELETE_ORDERS":
      return [];
    default:
      return state;
  }
};

// Driver Info
const DRIVER_INFO = (
  state = {
    about: "",
    name: "",
    orders: [],
    owner: "",
    vehicleInfo: {
      vehicleName: "",
      vehicleColor: "",
      vehicleNumberPlate: "",
    },
    verified: false,
  },
  { type, payload }
) => {
  switch (type) {
    case "GET_DRIVER_INFO":
      return payload;
    case "ACCEPT_ORDER":
      const updatedInfo = state;
      updatedInfo.orders = [...state.orders, payload];
      return updatedInfo;
    default:
      return state;
  }
};

const Driver = {
  ORDERS,
  DRIVER_INFO,
};
export default Driver;
