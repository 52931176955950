import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Functions from "../../abstracts/Functions";
import JobNav from "./assets/JobNav";
import FLASH, { RESTORE_FLASH } from "../../redux/actions/Flash";
import Messages from "../../abstracts/Messages";

const CodeGenerator = () => {
  Functions.pageInfo({
    title: "Ordapple | Agent Code Generator",
    desc: "Generates Agent Code. For Authorized staff only",
  });

  //   Use selector
  const flash = useSelector((s) => s.flash);

  // Use state
  const [level, setLevel] = useState("01");

  // Dispatch
  const dispatch = useDispatch();

  // Use effect
  useEffect(() => {
    dispatch(RESTORE_FLASH());
  }, []);

  //   Generate the agent code
  const generateCode = (e) => {
    e.preventDefault();

    // Random number
    const rn = Math.floor(Math.random() * 1000000000);

    // Concatenate the level of the code and the random number
    // that is generated
    const concatStr = `${level}${rn}${Date.now()}`;

    // Dispaly the code to the user
    dispatch(FLASH({ err: "", success: `Code: ${concatStr}`, warn: "" }));
  };

  //   Clear the code to create a new one
  const clearCode = (e) => {
    e.preventDefault();
    dispatch(RESTORE_FLASH());
  };

  // On change event
  const onChange = (e) => {
    const { value } = e.target;

    setLevel(value);
  };

  return (
    <>
      <JobNav page="" />
      <div className="register-login login">
        <div className="form">
          {/* Heading */}
          <div className="heading">
            <h1>Code Generator</h1>
          </div>

          {/* Flash messages */}
          {flash.err !== "" && <Messages err={flash.err} />}
          {flash.success !== "" && <Messages success={flash.success} />}

          {/* Form  */}
          <form className="form-slide">
            <div className="passwords">
              <select
                name="pickUpTime"
                value={level}
                id="pickUpTime"
                onChange={onChange}
              >
                <option value="01">Level 1</option>
                <option value="02">Level 2</option>
                <option value="03">Level 3</option>
              </select>
            </div>
            <br />

            <button
              type="submit"
              className="submit-button"
              onClick={generateCode}
            >
              Generate
            </button>
            <button type="submit" className="dry" onClick={clearCode}>
              Clear
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default CodeGenerator;
