import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import FLASH, { RESTORE_FLASH } from "../redux/actions/Flash";
import Functions from "../abstracts/Functions";
import BuyerActions from "../redux/actions/BuyerActions";
import { SetStatus, NewInbox } from "./Requests";
import Styles from "../abstracts/Styles";

const ProdCard = ({
  page,
  shopName,
  setDisplay,
  img,
  productName,
  productCurrency,
  productPrice,
  productId,
  shopId,
  shopOwner,
  shopVerified,
  likes,
}) => {
  // Selecting states
  const user = useSelector((state) => state.user);
  const userID = user._id;

  // Dispatch
  const dispatch = useDispatch();

  // History
  const history = useHistory();

  // LIKE and UNLIKE functions

  const like = (e, productID, shopID, likes) => {
    dispatch(RESTORE_FLASH());
    setDisplay(true);
    e.preventDefault();

    // adding the user's id to the likes list in the product object
    likes.push(userID);

    axios({
      url: `${Functions.getAPI()}/like/${productID}`,
      method: "POST",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        if (user._id !== shopOwner) {
          await NewInbox(
            {
              text: "Liked your item",
              owner: shopOwner,
              sender: user._id,
              username: `${user.firstName}`,
              url: `/prod/${productName}/${productId}/${shopId}`,
            },
            async () => {
              await SetStatus(shopOwner);
            }
          );
        }

        dispatch(FLASH({ err: "", success: data.success, warn: "" }));
        Functions.sendNotif({
          _id: shopID,
          body: `${user.firstName} liked your item!`,
          url: "https://www.ordapple.com/admin/account",
          fetchUrl: `${Functions.getAPI()}/sendNotif`,
        });
      })
      .catch((err) => {
        const res = err.response;
        if (res) {
          console.log(res.data);
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
        } else {
          console.log(err);
        }
      });
  };

  const unlike = (e, productID, shopID, likes) => {
    dispatch(RESTORE_FLASH());
    setDisplay(true);
    e.preventDefault();

    if (
      page === "shopItems" ||
      page === "itemResults" ||
      page === "home" ||
      page === "item-preview"
    ) {
      // Removing the user's id from the likes of a product
      likes.map((el, ind) => {
        if (el === userID) {
          likes.splice(ind, 1);
        }
        return el;
      });

      // Updating the database
      axios({
        url: `${Functions.getAPI()}/unlike/${productID}`,
        method: "POST",
        withCredentials: true,
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          dispatch(FLASH({ err: "", success: data.success, warn: "" }));
        })
        .catch((err) => {
          const res = err.response;
          if (res) {
            console.log(res.data);
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(err);
          }
        });
    } else if (page === "buyer-account") {
      // Removing the user's id from the likes of a product
      likes.map((el, ind) => {
        if (el === userID) {
          likes.splice(ind, 1);
        }
        return el;
      });

      // Updatin the database
      axios({
        url: `${Functions.getAPI()}/unlike/${productID}`,
        method: "POST",
        withCredentials: true,
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          // DISPATCH
          dispatch(BuyerActions.UNLIKE(productId));
          dispatch(FLASH({ err: "", success: data.success, warn: "" }));
        })
        .catch((err) => {
          const res = err.response;
          if (res) {
            console.log(res.data);
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(err);
          }
        });
    }
  };

  //  Render functions
  const imageRender = (img) => {
    if (img !== "") {
      return <img src={img} alt="Product" loading="lazy" />;
    } else {
      return <p>No image</p>;
    }
  };

  // View product
  const viewProductInfo = (e) => {
    e.preventDefault();
    history.push(`/prod/${productName}/${productId}/${shopId}`);
  };

  // Render functions
  const renderProductCard = () => {
    return (
      <Link
        to={`/prod/${productName}/${productId}/${shopId}`}
        className={page === "item-preview" ? "card embla__slide" : "card"}
        style={page === "item-preview" ? Styles.embla__slide : {}}
      >
        <div className="prod-img">{imageRender(img)}</div>
        <div className="prod-info">
          <p className="prod-name">{productName}</p>
          <p className="shop-name">
            <span>
              By {shopName}{" "}
              {shopVerified && <i className="fas fa-check verified"></i>}
            </span>{" "}
          </p>
          <div className="price-likes">
            <h3>{`${productCurrency} ${productPrice}`}</h3>
            <p className="likes">
              <i
                className={`fas fa-heart ${
                  likes.includes(userID) ? "active" : ""
                }`}
                onClick={(e) => {
                  likes.includes(userID)
                    ? unlike(e, productId, shopId, likes)
                    : like(e, productId, shopId, likes);
                }}
              ></i>
              <span className="dot"></span>
              <span
                className={`stat ${likes.includes(userID) ? "active" : ""}`}
              >
                {Functions.numFormatter(likes.length)}
              </span>
            </p>
          </div>

          {/* If the user already liked the product (use the .include() method to check if the id of the user exists in the likes) */}
          {/* {likes.includes(userID) ? (
            <form action={`/unlike/${productId}`} method="POST">
              <input type="hidden" name="shop" value={shopId} />
              <button
                className="unlike"
                type="submit"
                onClick={(e) => {
                  unlike(e, productId, shopId, likes);
                }}
              >
                <i className="fas fa-thumbs-down"></i> Unlike
              </button>
            </form>
          ) : (
            <form action={`/like/${productId}`} method="POST">
              <input type="hidden" name="shop" value={shopId} />
              <button
                type="submit"
                onClick={(e) => {
                  if ("following" in user) {
                    like(e, productId, shopId, likes);
                  } else {
                    setDisplay(true);
                    e.preventDefault();
                    dispatch(
                      FLASH({
                        err: "",
                        success: "",
                        warn: "You are not logged in!",
                      })
                    );
                  }
                }}
              >
                <i className="fas fa-thumbs-up"></i> Like
              </button>
            </form>
          )} */}

          <form>
            <button onClick={viewProductInfo}>Buy</button>
          </form>
        </div>
      </Link>
    );
  };

  return <>{renderProductCard()}</>;
};

export default ProdCard;
