import { Link } from "react-router-dom";
import PricingCard from "./assets/PricingCard";

const Pricing = () => {
  const sublist = [
    {
      name: "Basic",
      price: "R350(pa) - R35(pm)",
      includes: [
        "Space for 5 items",
        "Keep 75% of sales",
        "Transaction tracking system",
        "Order tracking system",
        "Unlimited cashout requests",
        "Unlimited client support",
        "In-app promotion",
        "Social media promotion",
      ],
      url: "/admin/apply/basic",
    },
    {
      name: "Standard",
      price: "R500(pa) - R120(pm)",
      includes: [
        "Space for 25 items",
        "Keep 85% of sales",
        "Transaction tracking system",
        "Order tracking system",
        "Unlimited cashout requests",
        "Unlimited client support",
        "In-app promotion",
        "Social media promotion",
      ],
      url: "/admin/apply/standard",
    },
    {
      name: "Premium",
      price: "R1200(pa) - R300(pm)",
      includes: [
        "Unlimited space for items",
        "Keep 95% of sales",
        "Transaction tracking system",
        "Order tracking system",
        "Unlimited cashout requests",
        "Unlimited client support",
        "In-app promotion",
        "Social media promotion",
      ],
      url: "/admin/apply/premium",
    },
  ];
  return (
    <div className="services-container">
      <div id="pricing" className="pricing-container">
        <div className="heading">
          <h1>Sell here</h1>
          <span></span>
        </div>

        <div className="pricing-content">
          {sublist.map((i, ind) => (
            <PricingCard sub={i} key={ind} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
