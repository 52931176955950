import { useState, createRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Messages from "../../abstracts/Messages.jsx";
import AuthActions from "../../redux/actions/AuthActions.js";
import FLASH, { RESTORE_FLASH } from "../../redux/actions/Flash";
import axios from "axios";
import USER from "../../redux/actions/User.jsx";
import BuyerActions from "../../redux/actions/BuyerActions.js";
import Functions from "../../abstracts/Functions";

const Buyer = ({ history }) => {
  // Redux
  const dispatch = useDispatch();
  const flash = useSelector((state) => state.flash);

  Functions.pageInfo({
    title: "Ordapple | Login To Your Account",
    desc: "Login To Your User Account",
  });

  // Use effect
  useEffect(() => {
    const afterReg = /You have now registered/g;
    const afterAccAct = /Your account is now verified/gi;
    if (!afterReg.test(flash.success) || !afterAccAct.test(flash.success)) {
      dispatch(RESTORE_FLASH());
    }
  }, [dispatch, flash.success]);

  // Messages
  const [msg, setMsg] = useState({});

  // States
  const [emailVal, setEmailVal] = useState("");
  const [passVal, setPassVal] = useState("");

  // Refs
  const email = createRef();
  const password = createRef();

  // On change events
  const emailOnchange = (e) => {
    const emailRegx = /@/i;
    const { value, style } = e.target;
    setEmailVal(value);
    if (!emailRegx.test(value)) {
      setMsg({ email: "Must include '@'", pass: "" });
      style.border = "3px solid red";
    } else {
      setMsg({ email: "", pass: "" });
      style.border = "3px solid rgb(0, 200, 0)";
    }
  };
  const passOnchange = (e) => {
    const { value, style } = e.target;
    setPassVal(value);
    if (value.length < 5) {
      style.border = "3px solid red";
      setMsg({ email: "", pass: "Must be 5 char or more" });
    } else {
      setMsg({ email: "", pass: "" });
      style.border = "3px solid rgb(0, 200, 0)";
    }
  };

  const submitHandler = (e) => {
    const emailRegx = /\w@\w./i;
    if (email.current.value.trim() === "") {
      e.preventDefault();
      email.current.style.border = "3px solid red";
      setMsg({ email: "Required field!", pass: "" });
    } else if (password.current.value.trim() === "") {
      e.preventDefault();
      password.current.style.border = "3px solid red";
      setMsg({ email: "", pass: "Required field!" });
    } else if (!emailRegx.test(email.current.value)) {
      e.preventDefault();
      email.current.style.border = "3px solid red";
      setMsg({ email: "Must include '@'", pass: "" });
    } else if (password.current.value.length < 5) {
      e.preventDefault();
      password.current.style.border = "3px solid red";
      setMsg({ email: "", pass: "Must be 5 char or more" });
    } else {
      e.preventDefault();

      // Loading animation on submit button
      e.target.classList.add("btn-animate");
      e.target.innerText = "Processing...";

      const email = emailVal;
      const password = passVal;

      axios({
        url: `${Functions.getAPI()}/login`,
        method: "POST",
        withCredentials: true,
        data: {
          email,
          password,
        },
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          const user_info = {
            _id: data.user._id,
            firstName: data.user.firstName,
            lastName: data.user.lastName,
            email: data.user.email,
            phone: data.user.phone,
            token: data.user.token,
            admin: data.user.admin,
            driver: data.user.driver,
            accountActive: data.user.accountActive,
            employee: data.user.employee,
            following: data.user.following,
            liked: data.user.liked,
          };

          const buyer_orders = data.user.orders;

          // Dispatching
          dispatch(AuthActions.login());
          dispatch(USER.USER_INFO(user_info));
          dispatch(BuyerActions.BUYER_ORDERS(buyer_orders.reverse()));
          dispatch(BuyerActions.ADD_TO_CART(data.user.cart));
          dispatch(FLASH({ err: "", success: "", warn: "" }));

          // Redirecting the user to shops page
          history.push("/shop");
        })
        .catch((err) => {
          // Removing the loading animation from the button
          e.target.classList.remove("btn-animate");
          e.target.innerText = "Submit";

          const res = err.response;
          if (res) {
            const data = res.data;
            dispatch(FLASH({ err: data.message, success: "", warn: "" }));
          } else {
            console.log(err);
          }
        });

      setMsg({ email: "", pass: "" });
    }
  };

  return (
    // Register section
    <div className="register-login login">
      <div className="form">
        <div className="account-nav">
          <Link className="active" to="/login">
            Login
          </Link>
          <Link to="/register">Register</Link>
        </div>

        {/* Flash messages */}

        {flash.err !== "" && <Messages err={flash.err} />}
        {flash.success !== "" && <Messages success={flash.success} />}

        {/* Form  */}
        <form className="form-slide">
          {/* User's email  */}
          <input
            type="email"
            className="formInput"
            name="email"
            id="email"
            placeholder="E-mail"
            value={emailVal}
            onChange={emailOnchange}
            ref={email}
          />
          <p style={{ color: "red" }} id="email-error">
            {msg.email}
          </p>

          {/* Password */}
          <div className="passwords">
            <input
              type="password"
              className="formInput"
              name="password"
              id="password"
              placeholder="Password"
              value={passVal}
              onChange={passOnchange}
              ref={password}
            />
          </div>
          <p style={{ color: "red" }} id="password-error">
            {msg.pass}
          </p>
          <br />

          <button
            type="submit"
            className="submit-button"
            onClick={submitHandler}
          >
            Submit
          </button>
          <br />
        </form>
        <Link to="/forgot-password">Forgot Password</Link>
      </div>
    </div>
  );
};

export default Buyer;
